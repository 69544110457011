import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import {
  ActivatedRoute,
  Params,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Exercise, ExerciseStatusInfo, LoginOrigin, User } from '../../models';
import { Settings } from '../../models/shared/settings.model';
import {
  AuthenticationService,
  ExerciseService,
  IntervalService,
  PreferenceService,
  SettingsService,
  UsersService,
} from '../../services';
import {
  PasswordChangeDialogComponent,
  PasswordChangeDialogInput,
} from '../../views/shared/password-change-dialog/password-change-dialog.component';
import { UserRedirectDialogComponent } from './user-redirect-dialog/user-redirect-dialog.component';
import { SidebarService } from '../../services/shared/sidebar.service';
import { LanguageUtils } from '../../shared/language.utils';
import { TranslateService } from '@ngx-translate/core';
import { ExerciseListEvent } from '../../services/gamenet/exercise.service';

@UntilDestroy()
@Component({
  selector: 'isa-intro-layout',
  templateUrl: 'base-layout.component.html',
  styleUrls: ['base-layout.component.scss'],
})
export class BaseLayoutComponent implements OnInit, AfterViewInit {
  settings: Settings;
  currentUser: User;
  currentExercise: Exercise;
  exerciseStatus$: Observable<ExerciseStatusInfo>;
  @ViewChild('contentBody', { static: true })
  contentBody: ElementRef;
  @ViewChild('nav')
  sidenav: MatSidenav;
  @ViewChild('notifications')
  notifications: MatSidenav;
  // isSideNavHidden change marks a purposeful change by the user. Use this.sidenav.close() if changing automatic behaviour
  isSideNavHidden = false;
  isHeaderHidden = false;
  isFooterHidden = false;
  passwordChangeDialogRef: MatDialogRef<PasswordChangeDialogComponent>;
  loading: boolean;
  selectedLanguage: string = 'en';
  filteredLanguages: string[] = [];
  enabledLanguages: string[] = [];
  isNotificationsContentShown = false;
  newNotifications: number;
  NOTIFICATIONS_PAGE_SIZE = 10;
  userRedirectDialogRef: MatDialogRef<UserRedirectDialogComponent>;
  readonly LOGIN_ORIGIN = LoginOrigin;

  constructor(
    private settingsService: SettingsService,
    private authenticationService: AuthenticationService,
    private exerciseService: ExerciseService,
    private preferenceService: PreferenceService,
    private usersService: UsersService,
    private intervalService: IntervalService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private observer: BreakpointObserver,
    private sidebar: SidebarService,
    private translate: TranslateService,
    private languageUtils: LanguageUtils
  ) {
    translate.setDefaultLang('en');
  }

  private handleDefaultExerciseAndGetNotifications(defaultExerciseId: string): Subscription {
    return this.exerciseService.getExercise(defaultExerciseId).subscribe((e) => {
      this.exerciseService.setActiveExercise(e);
    });
  }

  ngOnInit(): void {
    this.authenticationService
      .getCurrentUser()
      .pipe(
        switchMap((user) => {
          this.currentUser = user;
          return this.usersService.listenForUserEvents();
        }),
        untilDestroyed(this)
      )
      .subscribe((action) => {
        if (action === 'LOGOUT') {
          this.openUserRedirectDialog();
        }
      });

    this.settingsService.settingsChanged.pipe(untilDestroyed(this)).subscribe((settings) => {
      this.setSettings(settings);
    });

    this.settingsService.getSettings().subscribe((settings) => {
      this.selectedLanguage = this.languageUtils.getLanguage();
      this.enabledLanguages = settings.enabledLanguages;
      this.filteredLanguages = this.languageUtils.filterLanguages(this.enabledLanguages);
      this.settingsService.settingsChanged.next(settings);
    });

    this.exerciseService.activeExercise.pipe(untilDestroyed(this)).subscribe((e) => {
      this.currentExercise = e;
    });

    this.exerciseStatus$ = combineLatest([
      this.intervalService.getWidgetRefreshInterval(),
      this.exerciseService.activeExercise,
    ]).pipe(
      switchMap(([_, exercise]) => {
        if (exercise) {
          return this.exerciseService.getExerciseStatus(exercise.id);
        } else {
          return of(null);
        }
      }),
      untilDestroyed(this)
    );

    this.exerciseService.exerciseListChanged
      .pipe(untilDestroyed(this))
      .subscribe((exerciseListEvent) => {
        if (this.isCurrentExerciseDeleted(exerciseListEvent)) {
          this.exerciseService.setActiveExercise(null);
        } else if (this.isCurrentExerciseModified(exerciseListEvent)) {
          this.selectExercise(exerciseListEvent.exerciseId, true);
        }
      });

    combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
      .pipe(
        map(([params, queryParams]) => {
          return {
            isSideNavHidden:
              BaseLayoutComponent.hasParam(params, 'sideNavHidden') ||
              BaseLayoutComponent.hasParam(queryParams, 'sideNavHidden'),
            isHeaderHidden:
              BaseLayoutComponent.hasParam(params, 'headerHidden') ||
              BaseLayoutComponent.hasParam(queryParams, 'headerHidden'),
            isFooterHidden:
              BaseLayoutComponent.hasParam(params, 'footerHidden') ||
              BaseLayoutComponent.hasParam(queryParams, 'footerHidden'),
          };
        })
      )
      .subscribe((params) => {
        this.isSideNavHidden = params.isSideNavHidden;
        this.isHeaderHidden = params.isHeaderHidden;
        this.isFooterHidden = params.isFooterHidden;
      });
  }

  private isCurrentExerciseModified(exerciseListEvent: ExerciseListEvent) {
    return this.currentExercise && this.currentExercise.id === exerciseListEvent?.exerciseId;
  }

  private isCurrentExerciseDeleted(exerciseListEvent: ExerciseListEvent) {
    return (
      exerciseListEvent.exerciseId === this.currentExercise.id &&
      exerciseListEvent.event === 'DELETE'
    );
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loading = false;
      }
    });

    // The empty setTimeout is one of those Angular mysteries - this code should be in ngAfterViewInit bc sidenav(s)
    // should be rendered by this time, but they are not, but the empty setTimeout resolves it, but it shouldn’t(?)…
    setTimeout(() => {
      this.observer
        .observe(['(max-width: 1020px)'])
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          if (this.sidenav && this.notifications) {
            if (res.matches) {
              this.sidenav.mode = 'over';
              this.notifications.mode = 'over';
              this.sidenav.close();
              this.isNotificationsContentShown = false;
            } else {
              this.sidenav.mode = 'side';
              this.notifications.mode = 'side';
              if (!this.isSideNavHidden) {
                this.sidenav.open();
              }
            }
          }
        });
    });
  }

  openUserRedirectDialog(): void {
    this.userRedirectDialogRef = this.dialog.open(UserRedirectDialogComponent, {
      disableClose: true,
      data: {
        message: 'Mission has ended',
      },
    });

    this.userRedirectDialogRef.componentInstance.onTimeoutEnd.subscribe(() => {
      this.redirectToExternalUrl();
      setTimeout(() => this.userRedirectDialogRef.close(), 1000);
    });
    this.userRedirectDialogRef.afterClosed().subscribe(() => (this.userRedirectDialogRef = null));
  }

  setSettings(settings: Settings) {
    this.settings = settings;

    this.preferenceService.getPreferences(true).subscribe((preferences) => {
      if (
        !this.exerciseService.activeExercise.value &&
        this.isRouteInModule('gamenet') &&
        this.settings &&
        this.settings.gamenetEnabled
      ) {
        this.handleDefaultExerciseAndGetNotifications(preferences.defaultExerciseId);
      }
    });
  }

  logout(): void {
    this.authenticationService.logout().subscribe(() => {
      this.router.navigate(['/intro/login']);
    });
  }

  hasExternalRedirectUrl(): boolean {
    return (
      this.currentUser?.loginOrigin === LoginOrigin.EXTERNAL &&
      this.currentUser?.redirectUrl != null
    );
  }

  redirectToExternalUrl() {
    this.authenticationService
      .logout()
      .subscribe(() => this.navigateToExternalUrl(this.currentUser?.redirectUrl));
  }

  navigateToExternalUrl(url?: string) {
    // TODO: write tests
    if (url == null) {
      this.router.navigateByUrl('/intro/login/');
      return;
    }

    window.location.href = url;
  }

  toggleNotifications(): void {
    this.isNotificationsContentShown = !this.isNotificationsContentShown;
    if (this.isNotificationsContentShown) {
      this.usersService.updateLastSeenNotifications().subscribe();
    }
  }

  isRouteInModule(module: string): boolean {
    return this.router.url.includes(module);
  }

  openPasswordChangeDialog() {
    this.passwordChangeDialogRef = this.dialog.open(PasswordChangeDialogComponent, {
      disableClose: false,
      data: {
        onPasswordChange: (password: string) =>
          this.usersService.changeCurrentUserPassword(password),
      } as PasswordChangeDialogInput,
    });

    this.passwordChangeDialogRef.afterClosed().subscribe(() => {
      this.passwordChangeDialogRef = null;
    });
  }

  isLocalUser(): boolean {
    return this.currentUser && this.currentUser.loginOrigin === LoginOrigin.LOCAL;
  }

  isObserverUser(): boolean {
    return this.currentUser && this.currentUser.isObserver();
  }

  newNotificationsHandler(numberOfNotifications: number) {
    this.newNotifications = numberOfNotifications;
  }

  toggleNav(): void {
    if (this.sidenav.opened) {
      this.sidenav.close();
    } else {
      this.sidenav.open();
    }
    this.sidebar.setSidebarStatus(this.sidenav.opened);
    this.isSideNavHidden = !this.sidenav.opened;
  }

  switchLanguage(language: string) {
    this.languageUtils.setLang(language);
    this.translate.use(language);
    this.filteredLanguages = this.languageUtils.filterLanguages(this.enabledLanguages);
    this.selectedLanguage = language;
  }

  selectExercise(exerciseId: string, silent: boolean = false): void {
    this.exerciseService.selectExercise(exerciseId, silent);
  }

  private static hasParam(params: Params, paramName: string): boolean {
    return params && params[paramName] === 'true';
  }
}
