<div class="incident-report-confirm-dialog">
  <h2 matDialogTitle>{{ 'ui.incidentReport.blueIncidentReport' | translate }}</h2>
  <mat-dialog-content class="incident-report-confirm-dialog-content">
    <div class="isa-details-box">
      @if (aiFeaturesEnabled && incidentReportsData.aiAssessmentStatus) {
        <mat-card class="ai-assessment-results">
          <mat-card-title>
            <mat-icon>android</mat-icon>
            <span
              >{{ 'ui.reports.autoAssessStatus' | translate }}:
              {{ incidentReportsData.aiAssessmentStatus }}</span
            >
          </mat-card-title>
          @if (incidentReportsData.aiScore != null) {
            <div>{{ 'ui.reports.rating' | translate }}: {{ incidentReportsData.aiScore }}</div>
          }
          @if (incidentReportsData.aiFeedback) {
            <div>
              <mat-label>{{ 'ui.reports.aiFeedback' | translate }}</mat-label>
              <div class="report-content disable-uppercase">
                {{ incidentReportsData.aiFeedback }}
              </div>
            </div>
          }
        </mat-card>
      }

      <div class="row">
        <span class="label">{{ 'ui.incidentReport.details' | translate }}</span>
        @if (reportDetailsLoaded) {
          <quill-editor
            [ngModel]="reportDetails"
            [readOnly]="true"
            [modules]="{ toolbar: false }"
          ></quill-editor>
        }
        @if (!reportDetailsLoaded) {
          <cybexer-progress-spinner></cybexer-progress-spinner>
        }
      </div>
      <div class="row">
        <span class="label">{{ 'ui.time' | translate }}</span>
        {{ incidentReportsData?.timestamp | isaDate: 'medium' }}
      </div>
      <div class="row">
        <span class="label">{{ 'ui.team' | translate }}</span>
        {{ incidentReportsData?.teamId | isaTeamName }}
      </div>
      <div class="row">
        <span class="label">{{ 'ui.incidentReport.teamMember' | translate }}</span>
        {{ incidentReportsData?.blueTeamMember }}
      </div>
      <div class="row">
        <span class="label">{{ 'ui.incidentReport.target' | translate }}</span>
        {{ incidentReportsData?.targetId | isaTargetName: exercise }}
      </div>
      <div class="row">
        <span class="label">{{ 'ui.incidentReport.incidentType' | translate }}</span>
        {{ incidentReportsData?.incidentType | isaIncidentTypeName | translate }}
      </div>
    </div>
    @if (showFeedback()) {
      <mat-form-field class="full-width textarea feedback">
        <mat-label>{{ 'ui.incidentReport.feedback' | translate }}</mat-label>
        <textarea
          [disabled]="
            !hasPermissionToConfirmOrDeny ||
            incidentReportsData.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION
          "
          [(ngModel)]="feedback"
          matInput
          [cdkTextareaAutosize]
          tabindex="-1"
        ></textarea>
      </mat-form-field>
    }
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    @if (hasPermissionToConfirmOrDeny && aiFeaturesEnabled) {
      <button mat-raised-button (click)="sendToAIAssessment()">
        <i class="fas fa-magic-wand-sparkles status-ai-icon" aria-hidden="true"></i>
        {{ 'ui.incidentReport.autoAssess' | translate }}
      </button>
    }
    @if (
      hasPermissionToConfirmOrDeny &&
      incidentReportsData?.status === CONFIRMATION_STATUS.PENDING_CONFIRMATION
    ) {
      <cybexer-confirm-action>
        <button
          class="isa-button-confirm"
          (click)="submitConfirmation(CONFIRMATION_STATUS.CONFIRMED)"
          mat-raised-button
          color="primary"
        >
          <i class="fas fa-check-circle mat-button-fa" aria-hidden="true"></i
          >{{ 'ui.confirm' | translate }}
        </button>
      </cybexer-confirm-action>
      <cybexer-confirm-action>
        <button
          class="isa-button-deny"
          (click)="submitConfirmation(CONFIRMATION_STATUS.DENIED)"
          mat-raised-button
        >
          <i class="fas fa-exclamation-circle mat-button-fa" aria-hidden="true"></i
          >{{ 'ui.deny' | translate }}
        </button>
      </cybexer-confirm-action>
    }
    <button mat-button matDialogClose>{{ 'ui.cancel' | translate }}</button>
  </mat-dialog-actions>
</div>
