import {
  AfterViewInit,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CTFTaskStatus,
  CustomLogoConfiguration,
  Exercise,
  SmartCityBuilding,
  SmartCityBuildingDetails,
  SmartCityMapConfiguration,
  TargetStatus,
} from '../../../../models';
import {
  AbstractMesh,
  Animation,
  AnimationGroup,
  ArcRotateCamera,
  CascadedShadowGenerator,
  Color3,
  Color4,
  DirectionalLight,
  Engine,
  HemisphericLight,
  Mesh,
  PBRMaterial,
  ReflectionProbe,
  Scene,
  SceneLoader,
  ShadowGenerator,
  Space,
  StandardMaterial,
  TransformNode,
  Vector3,
  Viewport,
} from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import { GlowLayer } from '@babylonjs/core/Layers/glowLayer';
import { AdvancedDynamicTexture, Control } from '@babylonjs/gui';
import { SSAO2RenderingPipeline } from '@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/ssao2RenderingPipeline';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { WindowRefService } from '../../../../services/shared/window-ref.service';
import { SidebarService } from '../../../../services/shared/sidebar.service';
import { SmartCityService } from '../../../../services/gamenet/smart-city.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'isa-smart-city-visualization',
  templateUrl: './smart-city-visualization.component.html',
  styleUrl: './smart-city-visualization.component.css',
})
export class SmartCityVisualizationComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  @Input() cityState: SmartCityBuilding[];
  @Input() selectedCity: SmartCityMapConfiguration;
  @Input() selectedBuildingDetails: SmartCityBuildingDetails;
  @Input() teamId: string;
  @Output() showPanel = new EventEmitter<string>();
  isMapOpen = true;
  isVehicleCameraActive = false;
  exercise: Exercise;
  loading = true;
  selectedMesh: AbstractMesh | null = null;
  isInitialized = false;
  pickedDependencies = this.smartCityService.pickedDependencies;
  isInfoPanelOpen = this.smartCityService.isInfoPanelOpen;
  removedDependency = this.smartCityService.removedDependency;
  drone: AbstractMesh | null = null;
  waterLogo: AbstractMesh | null = null;
  trafficJam: AbstractMesh | null = null;
  droneFlyAway = new BehaviorSubject(false);
  scene: Scene;
  engine: Engine;
  materialDefault: PBRMaterial | null = null;
  materialUnderAttack: PBRMaterial | null = null;
  materialInactive: PBRMaterial | null = null;
  materialCompromised: PBRMaterial | null = null;
  materialGood: PBRMaterial | null = null;
  materialNotAvailable: PBRMaterial | null = null;
  materialNotAvailableDependency: PBRMaterial | null = null;
  materialGoodDependency: PBRMaterial | null = null;
  materialCompromisedDependency: PBRMaterial | null = null;
  radioTowers: { towerId: string; radioSignals: Mesh[] }[] = [];
  activeMeshes: AbstractMesh[];
  dependentMeshes: string[];
  meshOriginalMaterials: Map<AbstractMesh, any> = new Map();
  selectMaterial: PBRMaterial;
  private readonly ANIMATION_FRAME_RATE = 30;
  private camera: ArcRotateCamera;
  private guiCamera: ArcRotateCamera;
  private mapCamera: ArcRotateCamera;
  private gl: GlowLayer;
  private selectDependentMaterial: PBRMaterial;
  private autoRotationInterval: NodeJS.Timeout | undefined;
  private userActiveTimer: NodeJS.Timeout | undefined;
  private height: number = 40;
  private depth: number = 40;
  private mapBackgroundBox: Control;
  private light: DirectionalLight;
  private shadowGenerator: CascadedShadowGenerator;
  private initialCameraState: { alpha: number; beta: number; radius: number; target: Vector3 };
  private droneFlyAwaySubscription: Subscription;

  constructor(
    private ngZone: NgZone,
    private windowRef: WindowRefService,
    private sideBarService: SidebarService,
    private smartCityService: SmartCityService
  ) {
    effect(() => {
      if (!this.isInfoPanelOpen() && this.selectedBuildingDetails) {
        this.resetSelectedBuilding();
      }
    });

    effect(() => {
      this.pickedDependencies();
      this.addSelectDependentMaterialToDependencies();
    });

    effect(() => {
      this.removedDependency();
      this.removeSelectedDependentMaterial();
    });
  }

  private removeSelectedDependentMaterial() {
    const removedDependencyMesh = this.activeMeshes?.find(
      (mesh) => mesh.name === this.removedDependency()
    );
    if (removedDependencyMesh) {
      removedDependencyMesh.material = this.materialDefault;
    }
  }

  private addSelectDependentMaterialToDependencies() {
    if (this.selectedBuildingDetails && this.pickedDependencies()) {
      this.pickedDependencies().forEach((d) => {
        const dependentMesh = this.activeMeshes.find((mesh) => mesh.name === d);
        dependentMesh.material = this.selectDependentMaterial;
      });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.init());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isInitialized) {
      if (changes.cityState) {
        this.processMeshes();
      }

      if (changes.selectedCity) {
        this.mapChange();
      }

      if (changes.teamId) {
        this.teamChange();
      }
    }
  }

  init() {
    this.createScene();
    this.createGrid(34, 24, 1, 0.05);
    this.animate();
    this.initSideNavListener();
    this.isInitialized = true;
  }
  createScene(): void {
    this.engine = new Engine(this.canvas.nativeElement, true, null, false);
    this.scene = new Scene(this.engine);
    this.scene.clearColor = new Color4(0.33, 0.38, 0.43, 1);
    this.scene.ambientColor = new Color3(1, 1, 1);

    this.gl = new GlowLayer('glow', this.scene, {
      blurKernelSize: 32,
    });
    this.gl.intensity = 0.75;

    const ssaoRatio = {
      ssaoRatio: 0.5,
      combineRatio: 1.0,
    };
    const ssao2 = new SSAO2RenderingPipeline('ssao2', this.scene, ssaoRatio);
    ssao2.radius = 0.1; // Adjust the radius
    ssao2.totalStrength = 1.0; // Adjust the total strength
    ssao2.base = 0.5; // Adjust the base

    this.scene.fogMode = Scene.FOGMODE_LINEAR;
    this.scene.fogColor = new Color3(0.33, 0.38, 0.43);
    this.scene.fogDensity = 0.1;

    this.initLightAndShadow();
    this.initCameras();
    this.initMaterials();

    AdvancedDynamicTexture.ParseFromFileAsync('assets/3D_geo/UI/guiTexture.json')
      .then((gui: AdvancedDynamicTexture) => {
        this.mapBackgroundBox = gui.getControlByName('mapBackgroundBox');
        this.mapBackgroundBox.isVisible = true;
        gui.layer.layerMask = this.guiCamera.layerMask;
      })
      .catch((error: any) => {
        console.error('Error loading GUI:', error);
      });

    this.mapChange();

    this.scene.executeWhenReady(() => {
      this.processMeshes();
    });
  }

  private initLightAndShadow() {
    this.light = new DirectionalLight('light2', new Vector3(1, -2, -1), this.scene);
    this.light.intensity = 1;
    this.light.position = new Vector3(0, 150, -30);

    const hemisphericLight = new HemisphericLight('lighthemy', new Vector3(0.5, 1, 0), this.scene);
    hemisphericLight.intensity = 0.5;
    hemisphericLight.diffuse = new Color3(0.4, 0.4, 0.4); // Adjust diffuse color

    this.shadowGenerator = new CascadedShadowGenerator(2048, this.light);
    this.shadowGenerator.bias = 0.0005;
    this.shadowGenerator.usePercentageCloserFiltering = true;
    this.shadowGenerator.filteringQuality = ShadowGenerator.QUALITY_HIGH;
    this.shadowGenerator.stabilizeCascades = true;
  }

  private initCameras() {
    this.camera = new ArcRotateCamera('camera1', 1.5, 0.8, 10, new Vector3(-10, -5, 4), this.scene);
    this.initialCameraState = {
      alpha: this.camera.alpha,
      beta: this.camera.beta,
      radius: this.camera.radius,
      target: this.camera.target.clone(),
    };

    this.camera.lowerBetaLimit = 0.5; // Minimum vertical angle in radians (adjust as needed)
    this.camera.upperBetaLimit = Math.PI / 2 - 0.5; // Maximum vertical angle in radians (adjust as needed)
    this.camera.attachControl(this.canvas, true);
    this.camera.wheelPrecision = 5; // Adjust as needed
    this.camera.minZ = 0.1; // Adjust as needed
    this.camera.maxZ = 200; // Adjust as needed
    this.camera.lowerRadiusLimit = 1; // Adjust as needed
    this.camera.upperRadiusLimit = 30; // Adjust as needed
    this.camera.panningSensibility = 50; // Optional: Disable panning
    this.camera.angularSensibilityX = 250;
    this.camera.inertia = 0;
    this.camera.panningInertia = 0;
    this.camera.wheelDeltaPercentage = 0.05;

    this.startAutoRotation();
    this.setupUserInteractionListeners();
    this.startUserActivityTimer();

    this.guiCamera = new ArcRotateCamera('guicamera', 0, 0, 30, new Vector3(0, 0, 0), this.scene);
    this.guiCamera.attachControl(this.canvas, true);
    this.guiCamera.layerMask = 0x20000000;

    this.mapCamera = new ArcRotateCamera(
      'camera1',
      Math.PI / 1.95,
      0,
      0,
      new Vector3(-6, 40, -2),
      this.scene
    );

    let sizeW = 280;
    let sizeH = 280;
    const onResize = () => {
      let percW = sizeW / this.canvas.nativeElement.width;
      let percH = sizeH / this.canvas.nativeElement.height;
      this.mapCamera.viewport = new Viewport(1 - percW * 1.055, 1 - percH * 1.06, percW, percH);
    };
    this.scene.cameraToUseForPointers = this.guiCamera;
    this.engine.onResizeObservable.add(() => onResize());

    onResize();

    this.camera.viewport = new Viewport(0, 0, 1, 1);

    // Add onBeforeRenderObservable to enforce constraints on camera target
    this.scene.onBeforeRenderObservable.add(() => {
      const xLimitplus = 0;
      const xLimit = -10;
      const yLimit = this.height / 2;
      const zLimit = this.depth / 2;

      // Apply constraints to camera target's position
      if (this.camera.target.x < xLimit) {
        this.camera.target.x = xLimit;
      } else if (this.camera.target.x > xLimitplus) {
        this.camera.target.x = xLimitplus;
      }

      if (this.camera.target.z < -yLimit) {
        this.camera.target.z = -yLimit;
      } else if (this.camera.target.z > yLimit) {
        this.camera.target.z = yLimit;
      }
      if (this.camera.target.y < 2) {
        this.camera.target.y = 2;
      } else if (this.camera.target.y > zLimit) {
        this.camera.target.y = zLimit;
      }
    });
  }

  private initMaterials() {
    this.materialDefault = new PBRMaterial('material', this.scene);
    this.materialDefault.albedoColor = new Color3(1, 1, 1);
    this.materialDefault.roughness = 1.0;
    this.materialDefault.metallic = 0.0;

    this.materialInactive = new PBRMaterial('material', this.scene);
    this.materialInactive.albedoColor = new Color3(0.3, 0.3, 0.3);
    this.materialInactive.roughness = 1.0;
    this.materialInactive.metallic = 0.0;

    this.materialCompromised = new PBRMaterial('material', this.scene);
    this.materialCompromised.albedoColor = new Color3(1.2, 0.53, 0.18);
    this.materialCompromised.roughness = 1.0;
    this.materialCompromised.metallic = 0.0;

    this.materialCompromisedDependency = new PBRMaterial('material', this.scene);
    this.materialCompromisedDependency.albedoColor = new Color3(1.2, 0.8, 0.4);
    this.materialCompromisedDependency.roughness = 1.0;
    this.materialCompromisedDependency.metallic = 0.0;

    this.materialGood = new PBRMaterial('material', this.scene);
    this.materialGood.albedoColor = new Color3(0.2, 1.23, 0.18);
    this.materialGood.roughness = 1.0;
    this.materialGood.metallic = 0.0;

    this.materialNotAvailable = new PBRMaterial('material', this.scene);
    this.materialNotAvailable.albedoColor = new Color3(1.6, 0.2, 0.2);
    this.materialNotAvailable.roughness = 1.0;
    this.materialNotAvailable.metallic = 0.0;

    this.materialNotAvailableDependency = new PBRMaterial('material', this.scene);
    this.materialNotAvailableDependency.albedoColor = new Color3(1.8, 0.7, 0.7);
    this.materialNotAvailableDependency.roughness = 1.0;
    this.materialNotAvailableDependency.metallic = 0.0;

    this.materialGoodDependency = new PBRMaterial('material', this.scene);
    this.materialGoodDependency.albedoColor = new Color3(0.7, 1.4, 0.7);
    this.materialGoodDependency.roughness = 1.0;
    this.materialGoodDependency.metallic = 0.0;

    this.materialUnderAttack = new PBRMaterial('material', this.scene);
    this.materialUnderAttack.albedoColor = new Color3(1.6, 0.2, 0.2);
    this.materialUnderAttack.roughness = 1.0;
    this.materialUnderAttack.metallic = 0.0;

    const probe = new ReflectionProbe('main', 512, this.scene);
    this.selectMaterial = new PBRMaterial('newMaterial', this.scene);
    this.selectMaterial.reflectionTexture = probe.cubeTexture;
    this.selectMaterial.roughness = 0.0;
    this.selectMaterial.metallic = 1.0;
    this.selectMaterial.emissiveIntensity = 0.8;
    this.selectMaterial.emissiveColor = new Color3(0.4, 0.2, 0.4);
    this.selectMaterial.albedoColor = new Color3(0.8, 0.0, 0.3);

    this.selectDependentMaterial = new PBRMaterial('newMaterial', this.scene);
    this.selectDependentMaterial.reflectionTexture = probe.cubeTexture;
    this.selectDependentMaterial.roughness = 0.0;
    this.selectDependentMaterial.metallic = 1.0;
    this.selectDependentMaterial.emissiveIntensity = 0.8;
    this.selectDependentMaterial.emissiveColor = new Color3(0.2, 0.1, 0.2);
    this.selectDependentMaterial.albedoColor = new Color3(0.4, 0.0, 0.1);

    // Define the animation keys for color change
    const keys = [];
    keys.push({
      frame: 0,
      value: Color3.White(),
    });
    keys.push({
      frame: 30,
      value: new Color3(1.6, 0.2, 0.2),
    });
    keys.push({
      frame: 80,
      value: new Color3(1.6, 0.2, 0.2),
    });

    // Create an animation
    const colorAnimation = new Animation(
      'colorAnimation', // Animation name
      'albedoColor', // Property to animate
      25, // Frame rate
      Animation.ANIMATIONTYPE_COLOR3, // Animation type
      Animation.ANIMATIONLOOPMODE_YOYO // Loop mode
    );

    // Set animation keys
    colorAnimation.setKeys(keys);

    // Attach the animation to the material
    this.materialUnderAttack.animations = [];
    this.materialUnderAttack.animations.push(colorAnimation);

    // Start the animation
    this.scene.beginAnimation(this.materialUnderAttack, 0, 30, true);
  }

  private startAutoRotation() {
    if (!this.autoRotationInterval) {
      this.autoRotationInterval = setInterval(() => {
        // Update the camera alpha and beta angles
        this.camera.alpha += 0.0005; // Adjust rotation speed as needed
      }, 32); // Adjust the interval as needed for smoother or slower rotation
    }
  }

  private stopAutoRotation() {
    if (this.autoRotationInterval) {
      clearInterval(this.autoRotationInterval);
      this.autoRotationInterval = undefined;
    }
  }

  private setupUserInteractionListeners() {
    document.addEventListener('pointerdown', () => {
      this.stopAutoRotation();
      this.resetUserActivityTimer();
    });

    document.addEventListener('pointermove', () => {
      this.stopAutoRotation();
      this.resetUserActivityTimer();
    });

    document.addEventListener('pointerup', () => {
      this.stopAutoRotation();
      this.resetUserActivityTimer();
    });
  }

  private startUserActivityTimer() {
    this.userActiveTimer = setTimeout(() => {
      // User has been inactive, start auto rotation
      this.startAutoRotation();
    }, 5000); // Adjust the delay (in milliseconds) as needed
  }

  private resetUserActivityTimer() {
    if (this.userActiveTimer) {
      clearTimeout(this.userActiveTimer);
    }
    this.startUserActivityTimer();
  }

  processMeshes() {
    if (!this.scene) return;
    this.activeMeshes = this.scene.meshes.filter((mesh) => mesh.name.startsWith('ID_'));
    this.dependentMeshes = this.cityState
      ? this.cityState
          .filter((building) => building.dependencies)
          .map((building) => building.dependencies)
          .flat()
      : [];

    this.activeMeshes.forEach((mesh) => {
      this.calculateMeshStatus(mesh);
    });

    this.checkDroneStatus();
    this.checkWaterPlant();
    this.checkRadioTowers();
    this.checkRiverDam();
    this.checkTrafficJam();
  }

  private calculateMeshStatus(mesh: AbstractMesh) {
    const building = this.getBuilding(mesh.name);
    if (this.isUnderAttack(building)) {
      this.applyMaterial(mesh, this.materialUnderAttack);
      this.meshOriginalMaterials.set(mesh, this.materialUnderAttack);
    } else if (this.isNotAvailable(building)) {
      this.applyMaterial(mesh, this.materialNotAvailable);
      this.meshOriginalMaterials.set(mesh, this.materialNotAvailable);
      this.handleDependencies(building, this.activeMeshes, TargetStatus.NOT_AVAILABLE);
    } else if (this.isCompromised(building)) {
      this.applyMaterial(mesh, this.materialCompromised);
      this.meshOriginalMaterials.set(mesh, this.materialCompromised);
      this.handleDependencies(building, this.activeMeshes, TargetStatus.COMPROMISED);
    } else if (this.isGood(building)) {
      this.applyMaterial(mesh, this.materialGood);
      this.meshOriginalMaterials.set(mesh, this.materialGood);
      this.handleDependencies(building, this.activeMeshes, TargetStatus.GOOD);
    } else if (!this.dependentMeshes.includes(mesh.id)) {
      this.applyMaterial(mesh, this.materialDefault);
    }
  }

  isNotAvailable(building) {
    return (
      building?.target?.status === TargetStatus.NOT_AVAILABLE ||
      building?.task?.status === CTFTaskStatus.NOT_STARTED ||
      building?.task?.status === CTFTaskStatus.DEPENDENCIES_UNSOLVED ||
      building?.task?.status === CTFTaskStatus.ABANDONED ||
      building?.task?.status === CTFTaskStatus.LOCKED
    );
  }

  isCompromised(building) {
    return (
      building?.target?.status === TargetStatus.COMPROMISED ||
      building?.task?.status === CTFTaskStatus.PARTLY_SOLVED ||
      building?.task?.status === CTFTaskStatus.VALIDATING ||
      building?.task?.status === CTFTaskStatus.IN_PROGRESS
    );
  }

  isGood(building) {
    return (
      (building?.task?.status === CTFTaskStatus.SOLVED ||
        building?.target?.status === TargetStatus.GOOD) &&
      !this.isCompromised(building) &&
      !this.isNotAvailable(building)
    );
  }

  isUnderAttack(building) {
    return building?.target?.isUnderAttack;
  }

  applyMaterial(mesh, material) {
    if (!mesh) return;

    const isSelectedBuildingDependency = this.pickedDependencies()?.includes(mesh.name);
    if (isSelectedBuildingDependency) return;

    const isSelectedBuilding =
      this.selectedBuildingDetails && this.selectedBuildingDetails.id === mesh.name;

    if (!isSelectedBuilding) {
      mesh.material = material;
      mesh.material.fogEnabled = false;
    } else {
      mesh.material = this.selectMaterial;
      mesh.material.fogEnabled = false;
      this.meshOriginalMaterials.set(mesh, material);
    }
  }

  handleDependencies(
    building: SmartCityBuilding,
    activeMeshes: AbstractMesh[],
    status: TargetStatus
  ) {
    building?.dependencies?.forEach((id) => {
      const isSelectedBuildingDependency = building?.id === this.selectedBuildingDetails?.id;

      // if building has assigned task or target or is selected building dependency
      if (this.getBuilding(id) || isSelectedBuildingDependency) {
        return;
      }

      const dependentMesh = activeMeshes.find((mesh) => mesh.name === id);

      switch (status) {
        case TargetStatus.NOT_AVAILABLE: {
          this.applyMaterial(dependentMesh, this.materialNotAvailableDependency);
          break;
        }
        case TargetStatus.COMPROMISED: {
          this.applyMaterial(dependentMesh, this.materialCompromisedDependency);
          break;
        }
        case TargetStatus.GOOD: {
          this.applyMaterial(dependentMesh, this.materialGoodDependency);
          break;
        }
      }
    });
  }

  checkRiverDam() {
    if (!this.selectedCity.hasRiverDam) return;
    const damRiverMesh = this.scene.getMeshesById('dam_water')[0];
    const dam = this.getBuilding('ID_dam');
    if (!damRiverMesh) return;
    damRiverMesh.isVisible = this.isNotAvailable(dam) || this.isCompromised(dam);
  }

  checkTrafficJam() {
    if (!this.selectedCity.traffic) return;
    const trafficLight = this.getBuilding('ID_traffic_light');
    if (
      !!this.trafficJam &&
      !(this.isNotAvailable(trafficLight) || this.isCompromised(trafficLight))
    ) {
      this.trafficJam.dispose();
      this.trafficJam = null;
    } else if (
      !this.trafficJam &&
      (this.isNotAvailable(trafficLight) || this.isCompromised(trafficLight))
    ) {
      this.addTrafficJam();
    }
  }
  checkRadioTowers() {
    this.selectedCity.radioTower?.coordinates?.forEach((coordinates, index) => {
      const radioTowerId = 'ID_tower' + index;
      const radioTower = this.getBuilding(radioTowerId);
      const radioTowerMesh = this.radioTowers.find((tower) => tower.towerId === radioTowerId);

      const isUnavailableOrCompromised =
        this.isNotAvailable(radioTower) || this.isCompromised(radioTower);
      const hasRadioSignals = radioTowerMesh?.radioSignals;

      if (radioTower && isUnavailableOrCompromised) {
        radioTowerMesh?.radioSignals.forEach((signal) => signal.dispose());
        this.radioTowers = this.radioTowers.filter((tower) => tower.towerId !== radioTowerId);
        return;
      }

      if (!hasRadioSignals && !isUnavailableOrCompromised) {
        this.createRadioSignals(index, coordinates.x, coordinates.z);
      }
    });
  }

  checkWaterPlant() {
    if (!this.selectedCity.waterTreatmentPlant) return;
    const waterTreatmentBuilding = this.getBuilding('ID_water_treatment');
    if (
      !this.isNotAvailable(waterTreatmentBuilding) &&
      !this.isCompromised(waterTreatmentBuilding)
    ) {
      this.waterLogo?.dispose();
      this.waterLogo = null;
    } else if (
      !this.waterLogo &&
      (this.isNotAvailable(waterTreatmentBuilding) || this.isCompromised(waterTreatmentBuilding))
    ) {
      this.addWaterLogo(this.selectedCity.waterTreatmentPlant.coordinates);
    }
  }

  checkDroneStatus() {
    if (!this.selectedCity.drone) return;

    const building = this.getBuilding(this.selectedCity.drone.buildingId);
    const affectedAnimation = this.scene.getAnimationGroupByName(
      this.selectedCity.drone.affectedAnimationName
    );

    if (this.isGood(building)) {
      this.handleDroneTaskSolved(affectedAnimation);
    } else {
      this.handleDroneTaskNotSolved(affectedAnimation);
    }
  }

  handleDroneTaskSolved(affectedAnimation: AnimationGroup) {
    this.droneFlyAway.next(true);
    if (affectedAnimation) {
      affectedAnimation.restart();
    }
  }

  handleDroneTaskNotSolved(affectedAnimation: AnimationGroup | undefined) {
    if (!this.drone) {
      this.addDrone(this.selectedCity.drone.coordinates);
    }
    if (affectedAnimation) {
      affectedAnimation.goToFrame(4000);
    }
    affectedAnimation?.pause();
  }

  getBuilding(id: string): SmartCityBuilding {
    if (!this.cityState) return;
    return this.cityState.find((item: any) => item.id === id);
  }

  createGrid(xLines: number, zLines: number, spacing: number, transparency: number): void {
    const halfX = ((xLines - 1) * spacing) / 2;
    const halfZ = ((zLines - 1) * spacing) / 2;

    // Create lines along the X axis
    for (let i = 0; i < xLines; i++) {
      const xPos = i * spacing - halfX;
      const pointsX = [new Vector3(xPos, 0, -halfZ), new Vector3(xPos, 0, halfZ)];

      const linesX = MeshBuilder.CreateLines(`gridX-${i}`, { points: pointsX }, this.scene);
      linesX.color = new Color3(1, 0.8, 0.1); // Set RGB values
      linesX.alpha = transparency; // Set alpha (transparency) value
      linesX.isPickable = false;
    }

    // Create lines along the Z axis
    for (let i = 0; i < zLines; i++) {
      const zPos = i * spacing - halfZ;
      const pointsZ = [new Vector3(-halfX, 0, zPos), new Vector3(halfX, 0, zPos)];

      const linesZ = MeshBuilder.CreateLines(`gridZ-${i}`, { points: pointsZ }, this.scene);
      linesZ.color = new Color3(1, 0.8, 0.1); // Set RGB values
      linesZ.alpha = transparency; // Set alpha (transparency) value
      linesZ.isPickable = false;
    }
  }

  private addElecticityGrid() {
    SceneLoader.ImportMesh(
      '',
      'assets/3D_geo/Tartu/',
      'grid_test.gltf',
      this.scene,
      (newMeshes) => {
        if (newMeshes.length > 0) {
          newMeshes.forEach((mesh) => {
            if (mesh.material) {
              const material = mesh.material;

              if (material instanceof PBRMaterial) {
                const texture = material.albedoTexture; // Base Color texture

                if (texture) {
                  // Create an animation for the vOffset property
                  const vOffsetAnimation = new Animation(
                    'vOffsetAnimation',
                    'vOffset',
                    this.ANIMATION_FRAME_RATE,
                    Animation.ANIMATIONTYPE_FLOAT,
                    Animation.ANIMATIONLOOPMODE_CYCLE
                  );

                  // Animation keys
                  const keys = [
                    { frame: 0, value: 0 },
                    { frame: 20, value: 1 }, // 20 frames long animation
                  ];

                  vOffsetAnimation.setKeys(keys);

                  // Add the animation to the texture
                  texture.animations = [];
                  texture.animations.push(vOffsetAnimation);

                  // Start the animation
                  this.scene.beginAnimation(texture, 0, 20, true);
                }
              }
            }
          });
        }
      }
    );
  }

  private addIcon(customLogo: CustomLogoConfiguration) {
    SceneLoader.ImportMesh('', 'assets/3D_geo/', customLogo.fileName, this.scene, (meshes) => {
      const logo = meshes[0];
      logo.billboardMode = Mesh.BILLBOARDMODE_Y;
      logo.position = new Vector3(
        customLogo.coordinates.x,
        customLogo.coordinates.y,
        customLogo.coordinates.z
      );

      if (customLogo.scale) {
        logo.scaling = new Vector3(customLogo.scale, customLogo.scale, customLogo.scale);
      }

      if (customLogo.color) {
        const material = new StandardMaterial('logoMaterial', this.scene);
        material.emissiveColor = customLogo.color;
        meshes[1].material = material;
        this.light.excludedMeshes.push(meshes[1]);
      }
    });
  }

  private addCustomLogo(customLogo: CustomLogoConfiguration) {
    SceneLoader.ImportMesh(
      '',
      'assets/3D_geo/',
      customLogo.fileName,
      this.scene,
      function (meshes) {
        for (const mesh of meshes) {
          mesh.billboardMode = Mesh.BILLBOARDMODE_Y;
          mesh.translate(
            new Vector3(
              customLogo.coordinates.x,
              customLogo.coordinates.y,
              customLogo.coordinates.z
            ),
            Space.WORLD
          );
        }
      }
    );
  }

  switchToTruckCamera() {
    if (this.scene.getCameraByName('followCamera')) {
      this.scene.activeCameras = [
        this.scene.getCameraByName('followCamera'),
        this.guiCamera,
        this.mapCamera,
      ];
      this.isVehicleCameraActive = true;
    }
  }

  switchToMainCamera() {
    this.scene.activeCameras = [this.camera, this.guiCamera, this.mapCamera];
    this.isVehicleCameraActive = false;
  }

  addDrone(droneCoordinates) {
    SceneLoader.ImportMesh('', 'assets/3D_geo/', 'drone.glb', this.scene, (meshes) => {
      const droneMaterial = new PBRMaterial('drone', this.scene);
      droneMaterial.albedoColor = Color3.FromHexString('#D8282D');
      droneMaterial.roughness = 1.0;
      droneMaterial.metallic = 0.0;
      meshes.forEach((mesh) => (mesh.material = droneMaterial));
      this.drone = meshes[0];
      this.drone.scaling = new Vector3(
        this.selectedCity.drone.scale,
        this.selectedCity.drone.scale,
        this.selectedCity.drone.scale
      );
      // Define the animation
      const droneAnimation = new Animation(
        'droneAnimation',
        'position',
        this.ANIMATION_FRAME_RATE,
        Animation.ANIMATIONTYPE_VECTOR3,
        Animation.ANIMATIONLOOPMODE_CYCLE
      );

      const keys = [];
      const numFrames = 120; // Number of frames for one loop
      for (let i = 0; i <= numFrames; i++) {
        const t = (i / numFrames) * 2 * Math.PI;
        const x = Math.sin(t) * this.selectedCity.drone.scale;
        const y = Math.sin(t) * Math.cos(t) * this.selectedCity.drone.scale;
        keys.push({
          frame: i,
          value: new Vector3(y + droneCoordinates.x, droneCoordinates.y, x + droneCoordinates.z),
        });
      }

      droneAnimation.setKeys(keys);

      this.drone.animations = [];
      this.drone.animations.push(droneAnimation);

      // Start the animation
      this.scene.beginAnimation(this.drone, 0, numFrames, true);

      this.droneFlyAwaySubscription = this.droneFlyAway.subscribe((value) => {
        if (value) {
          this.removeDrone();
          this.droneFlyAwaySubscription?.unsubscribe();
        }
      });
    });
  }

  private removeDrone() {
    if (this.drone && this.drone.position) {
      this.scene.stopAnimation(this.drone);
      const flyAwayAnimation = new Animation(
        'flyAwayAnimation',
        'position',
        this.ANIMATION_FRAME_RATE,
        Animation.ANIMATIONTYPE_VECTOR3,
        Animation.ANIMATIONLOOPMODE_CONSTANT
      );

      const flyAwayKeys = [
        { frame: 0, value: this.drone?.position?.clone() },
        {
          frame: 60,
          value: new Vector3(
            this.drone?.position.x,
            this.drone?.position.y + 10,
            this.drone?.position.z + 20
          ),
        },
      ];

      flyAwayAnimation.setKeys(flyAwayKeys);
      this.drone.animations = [];
      this.drone.animations.push(flyAwayAnimation);

      // Start the fly-away animation
      this.scene.beginAnimation(this.drone, 0, 60, false, 1, () => {
        this.droneFlyAway.next(false);
        this.drone.dispose();
        this.drone = null;
      });

      if (this.selectedCity.drone.affectedAnimationName) {
        this.scene.getAnimationGroupByName(this.selectedCity.drone.affectedAnimationName)?.start();
      }
    }
  }

  addWaterLogo({ x, y, z }) {
    SceneLoader.ImportMesh('', 'assets/3D_geo/', 'water-icon.glb', this.scene, (meshes) => {
      this.waterLogo = meshes[0];
      this.waterLogo.billboardMode = Mesh.BILLBOARDMODE_Y;
      this.waterLogo.position = new Vector3(
        x - 0.4 * this.selectedCity.waterTreatmentPlant.scale,
        y + this.selectedCity.waterTreatmentPlant.scale,
        z + 0.7 * this.selectedCity.waterTreatmentPlant.scale
      );
      const material = new StandardMaterial('waterLogoMaterial', this.scene);
      material.emissiveColor = new Color3(0.1, 0.4, 0.6);
      meshes[1].material = material;
      this.light.excludedMeshes.push(meshes[1]);
    });
  }

  addTrainStation() {
    const logo: CustomLogoConfiguration = {
      fileName: 'train.glb',
      coordinates: {
        x: this.selectedCity.trainStation.coordinates.x,
        y: this.selectedCity.trainStation.coordinates.y,
        z: this.selectedCity.trainStation.coordinates.z,
      },
      color: Color3.FromHexString('#aa360e'),
      scale: this.selectedCity.trainStation.scale,
    };
    this.addIcon(logo);
  }

  addWaterTreatmentPlant({ x, y, z }) {
    SceneLoader.ImportMesh(
      '',
      'assets/3D_geo/',
      'water_treatment_plant.glb',
      this.scene,
      (meshes) => {
        meshes[0].position = new Vector3(x, y, z);
        meshes[0].scaling = new Vector3(
          this.selectedCity.waterTreatmentPlant.scale,
          this.selectedCity.waterTreatmentPlant.scale,
          this.selectedCity.waterTreatmentPlant.scale
        );
        meshes[1].receiveShadows = true;
        this.shadowGenerator.addShadowCaster(meshes[1]);
      }
    );
  }

  addTrafficJam() {
    SceneLoader.ImportMesh(
      '',
      this.selectedCity.scenePath,
      this.selectedCity.traffic.file,
      this.scene,
      (meshes) => {
        this.trafficJam = meshes[0];
        this.trafficJam.position.y = 0.01;
      }
    );
  }

  addTrafficLight() {
    SceneLoader.ImportMesh('', 'assets/3D_geo/', 'traffic_light.glb', this.scene, (meshes) => {
      const trafficLight = meshes[0];

      trafficLight.scaling = new Vector3(
        this.selectedCity.traffic.scale,
        this.selectedCity.traffic.scale,
        this.selectedCity.traffic.scale
      );

      trafficLight.position = new Vector3(
        this.selectedCity.traffic.coordinates.x,
        this.selectedCity.traffic.coordinates.y,
        this.selectedCity.traffic.coordinates.z
      );
    });
  }

  addBatteryBanks() {
    const logo: CustomLogoConfiguration = {
      fileName: 'electricity.glb',
      coordinates: {
        x: -9,
        y: 1.5,
        z: -12,
      },
      scale: 3,
      color: new Color3(0.7, 0.5, 0.1),
    };

    this.addIcon(logo);
  }

  addSubstation(substation, index) {
    SceneLoader.ImportMesh('', 'assets/3D_geo/', 'substation.glb', this.scene, (meshes) => {
      meshes[0].position = new Vector3(
        substation.coordinates.x,
        substation.coordinates.y,
        substation.coordinates.z
      );
      meshes[0].rotation = new Vector3(
        substation.rotation.x,
        substation.rotation.y,
        substation.rotation.z
      );
      meshes[0].scaling = new Vector3(substation.scale, substation.scale, substation.scale);
      meshes[1].receiveShadows = true;
      meshes[meshes.length - 1].name = 'ID_substation' + index;

      this.shadowGenerator.addShadowCaster(meshes[1]);
    });

    const logo: CustomLogoConfiguration = {
      fileName: 'electricity.glb',
      coordinates: {
        x: substation.coordinates.x + 0.2,
        y: 1.2 * substation.scale,
        z: substation.coordinates.z - 0.2,
      },
      scale: 3,
      color: new Color3(0.7, 0.5, 0.1),
    };

    this.addIcon(logo);
  }

  addAirport({ x, y, z }) {
    SceneLoader.ImportMesh(
      '',
      'assets/3D_geo/airport/',
      'runway_plus_plane.gltf',
      this.scene,
      (meshes) => {
        meshes[0].position = new Vector3(x, y, z);
        meshes[0].scaling = new Vector3(
          this.selectedCity.airport.scale,
          this.selectedCity.airport.scale,
          this.selectedCity.airport.scale
        );
        meshes[0].rotation = new Vector3(
          this.selectedCity.airport.rotation.x,
          this.selectedCity.airport.rotation.y,
          this.selectedCity.airport.rotation.z
        );
      }
    );
  }

  addRadioTower({ x, y, z }, index: number) {
    SceneLoader.ImportMesh('', 'assets/3D_geo/radioSignal/', 'tower.gltf', this.scene, (meshes) => {
      this.shadowGenerator.addShadowCaster(meshes[1]);
      meshes[0].position = new Vector3(x, y, z);
      meshes[0].scaling = new Vector3(
        this.selectedCity.radioTower.scale,
        this.selectedCity.radioTower.scale,
        this.selectedCity.radioTower.scale
      );
      meshes[1].name = 'ID_tower' + index;
      meshes[1].receiveShadows = true;
    });
  }

  createRadioSignals(index, x, z): void {
    const radioSignals: Mesh[] = [];
    const signalHeight = 1.2 * this.selectedCity.radioTower.scale;
    for (let i = 0; i < 4; i++) {
      radioSignals.push(
        this.createRadioSignal('torus-' + index, 0.5, new Vector3(x, signalHeight, z))
      );
    }
    const masterAnimation = this.createMasterAnimation();
    const animationTimer = new TransformNode('animationTimer', this.scene);
    animationTimer.animations = [masterAnimation];

    this.scene.beginAnimation(animationTimer, 0, 120, true);

    this.scene.onBeforeRenderObservable.add(() => {
      const frame = Math.floor(animationTimer.position.x);
      if (frame >= 1 && frame <= radioSignals.length) {
        this.scene.beginAnimation(radioSignals[frame - 1], 0, 120, false);
      }
    });

    this.radioTowers.push({ radioSignals, towerId: 'ID_tower' + index });
  }

  createMasterAnimation() {
    const masterAnimation = new Animation(
      'masterAnimation',
      'position.x',
      this.ANIMATION_FRAME_RATE,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CYCLE
    );

    const masterKeys = [];
    for (let i = 0; i <= 4; i++) {
      masterKeys.push({ frame: i * this.ANIMATION_FRAME_RATE, value: i + 1 });
    }
    masterAnimation.setKeys(masterKeys);

    return masterAnimation;
  }

  createRadioSignal(name, diameter, position): Mesh {
    const torus = MeshBuilder.CreateTorus(
      name,
      {
        thickness: 0.005,
        diameter: diameter,
        tessellation: 64,
      },
      this.scene
    );

    torus.position = position;

    const material = new StandardMaterial('torusMaterial', this.scene);
    material.emissiveColor = new Color3(1, 0, 0); // Red glow
    torus.material = material;

    torus.animations.push(...this.createScaleAnimations());
    torus.animations.push(this.createVisibilityAnimation());
    torus.animations.push(this.createEmissiveColorAnimation());

    return torus;
  }

  createScaleAnimations() {
    const scaleXAnimation = new Animation(
      'ScaleXAnimation',
      'scaling.x',
      this.ANIMATION_FRAME_RATE,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const scaleZAnimation = new Animation(
      'ScaleZAnimation',
      'scaling.z',
      this.ANIMATION_FRAME_RATE,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const scaleKeys = [
      { frame: 0, value: this.selectedCity.radioTower.scale },
      { frame: 120, value: 16 * this.selectedCity.radioTower.scale },
    ];

    scaleXAnimation.setKeys(scaleKeys);
    scaleZAnimation.setKeys(scaleKeys);

    return [scaleXAnimation, scaleZAnimation];
  }

  createVisibilityAnimation() {
    const visibilityAnimation = new Animation(
      'VisibilityAnimation',
      'visibility',
      this.ANIMATION_FRAME_RATE,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const visibilityKeys = [
      { frame: 0, value: 0 },
      { frame: 1, value: 1 },
      { frame: 120, value: 0 },
    ];

    visibilityAnimation.setKeys(visibilityKeys);
    return visibilityAnimation;
  }

  createEmissiveColorAnimation() {
    const emissiveColorAnimation = new Animation(
      'EmissiveColorAnimation',
      'material.emissiveColor',
      this.ANIMATION_FRAME_RATE,
      Animation.ANIMATIONTYPE_COLOR3,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const emissiveColorKeys = [
      { frame: 0, value: new Color3(1, 0, 0) },
      { frame: 120, value: new Color3(0, 0, 0) },
    ];

    emissiveColorAnimation.setKeys(emissiveColorKeys);
    return emissiveColorAnimation;
  }

  animate(): void {
    // We have to run this outside angular zones because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      const rendererLoopCallback = () => {
        this.scene.render();
      };

      // Register pointer event inside animate
      this.scene.onPointerDown = (event, pickInfo) => {
        if (!this.smartCityService.isDependencyPickMode()) {
          this.closeInfoPanel();
        }

        if (event.button === 0) {
          const pickInfo = this.scene.pick(
            this.scene.pointerX,
            this.scene.pointerY,
            undefined,
            false,
            this.camera
          );

          if (pickInfo.hit && pickInfo.pickedMesh) {
            const pickedMeshId = pickInfo.pickedMesh.name;
            const transformNodeName = pickInfo.pickedMesh.parent
              ? pickInfo.pickedMesh.parent.name
              : 'No parent transform node';
            if (pickedMeshId && pickedMeshId.startsWith('ID')) {
              if (
                this.smartCityService.isDependencyPickMode() &&
                pickedMeshId !== this.selectedBuildingDetails.id
              ) {
                if (this.pickedDependencies().includes(pickedMeshId)) return;
                this.pickedDependencies.update((values) => {
                  return [...values, pickInfo.pickedMesh.name];
                });
              } else {
                this.selectedMesh = pickInfo.pickedMesh;
                this.selectedMesh.edgesColor = new Color4(1, 0.2, 0, 1);

                // Save the original material of the selected mesh
                this.meshOriginalMaterials.set(this.selectedMesh, this.selectedMesh.material);

                this.selectedMesh.material = this.selectMaterial;
                this.showPanel.emit(this.selectedMesh.name);
              }
            }
          }
        }
      };

      this.camera.onViewMatrixChangedObservable.add(() => {
        // TODO hide info panel when camera starts rotating?
      });

      if (this.windowRef.document.readyState !== 'loading') {
        this.engine.runRenderLoop(rendererLoopCallback);
      } else {
        this.windowRef.window.addEventListener('DOMContentLoaded', () => {
          this.engine.runRenderLoop(rendererLoopCallback);
        });
      }

      this.windowRef.window.addEventListener('resize', () => {
        this.engine.resize();
      });
    });
  }

  initSideNavListener() {
    this.sideBarService.sidenavState$.subscribe((state) => {
      setTimeout(() => this.engine.resize(), 300);
    });
  }

  resetCamera(): void {
    this.camera.setPosition(
      new Vector3(
        this.initialCameraState.target.x,
        this.initialCameraState.target.y,
        this.initialCameraState.target.z
      )
    );
    this.camera.alpha = this.initialCameraState.alpha;
    this.camera.beta = this.initialCameraState.beta;
    this.camera.radius = this.initialCameraState.radius;
    this.camera.setTarget(this.initialCameraState.target);
  }

  mapChange() {
    this.closeInfoPanel();
    this.isVehicleCameraActive = false;
    this.resetCamera();
    this.height = this.selectedCity.height;
    this.depth = this.selectedCity.depth;
    this.scene.fogStart = this.selectedCity.fogStart;
    this.scene.fogEnd = this.selectedCity.fogEnd;
    this.disposeAllMeshes();
    this.radioTowers = [];
    this.waterLogo = null;
    this.drone = null;
    this.trafficJam = null;
    this.scene.animationGroups.forEach((a) => a.dispose());

    this.createGrid(60, 40, 1, 0.05);
    this.loadCityScene(this.selectedCity.scenePath, this.selectedCity.sceneFile, () => {
      this.loading = false;
      if (this.selectedCity.radioTower) {
        this.selectedCity.radioTower.coordinates.forEach((coordinates, index) =>
          this.addRadioTower(coordinates, index)
        );
      }

      if (this.selectedCity.airport) {
        this.addAirport(this.selectedCity.airport.coordinates);
      }

      if (this.selectedCity.waterTreatmentPlant) {
        this.addWaterTreatmentPlant(this.selectedCity.waterTreatmentPlant.coordinates);
      }

      if (this.selectedCity.trainStation) {
        this.addTrainStation();
      }

      if (this.selectedCity.traffic) {
        this.addTrafficLight();
      }

      if (this.selectedCity.battery) {
        this.addBatteryBanks();
      }

      if (this.selectedCity.substations.length) {
        this.selectedCity.substations.forEach((substation, index) => {
          this.addSubstation(substation, index);
        });
      }

      if (this.selectedCity.customLogo) {
        this.addCustomLogo(this.selectedCity.customLogo);
      }
    });

    this.scene.activeCameras = [this.camera, this.guiCamera, this.mapCamera];
  }

  private disposeAllMeshes() {
    while (this.scene.meshes.length) {
      this.scene.meshes[0].dispose();
    }
  }

  teamChange() {
    this.closeInfoPanel();
  }

  loadCityScene(scenePath: string, sceneFile: string, callback?: () => void) {
    this.loading = true;
    SceneLoader.Append(scenePath, sceneFile, this.scene, (loadedScene) => {
      const housing = loadedScene.getTransformNodeByName('Housing');
      const housing_ext = loadedScene.getTransformNodeByName('ext_houses');
      const ground = loadedScene.getTransformNodeByName('Ground');
      const water = loadedScene.getTransformNodeByName('River');
      const asphalt = loadedScene.getTransformNodeByName('Roads');
      const greens = loadedScene.getTransformNodeByName('Greens');

      if (housing instanceof TransformNode) {
        housing.getChildMeshes().forEach((mesh) => {
          mesh.receiveShadows = true;
          mesh.material = this.materialDefault;
          this.shadowGenerator.addShadowCaster(mesh);
        });
      }

      if (housing_ext instanceof TransformNode) {
        housing_ext.getChildMeshes().forEach((mesh) => {
          mesh.receiveShadows = true;
          mesh.material = this.materialInactive;
          mesh.material.fogEnabled = true;
          mesh.isPickable = false;
        });
      }

      if (ground instanceof TransformNode) {
        ground.getChildMeshes().forEach((mesh) => {
          (mesh.material as PBRMaterial).albedoColor = new Color3(0.09, 0.14, 0.2);
          (mesh.material as PBRMaterial).metallic = 0;
          (mesh.material as PBRMaterial).roughness = 100;
          (mesh.material as PBRMaterial).reflectivityColor = new Color3(0, 0, 0);
          (mesh.material as PBRMaterial).reflectionColor = new Color3(0, 0, 0);
          mesh.useVertexColors = false;
          mesh.material.fogEnabled = true;
          mesh.isPickable = false;
          mesh.receiveShadows = true;
        });
      }

      if (water instanceof TransformNode) {
        water.getChildMeshes().forEach((mesh) => {
          if (mesh.name === 'dam_water') {
            this.applyMaterial(mesh, this.materialNotAvailableDependency);
          } else {
            this.applyWaterMaterial(mesh);
          }
        });
      }

      if (asphalt instanceof TransformNode) {
        asphalt.getChildMeshes().forEach((mesh) => {
          (mesh.material as PBRMaterial).metallic = 0;
          (mesh.material as PBRMaterial).roughness = 100;
          (mesh.material as PBRMaterial).albedoColor = new Color3(0, 0, 0);
          (mesh.material as PBRMaterial).reflectivityColor = new Color3(0, 0, 0);
          (mesh.material as PBRMaterial).reflectionColor = new Color3(0, 0, 0);
          mesh.material.fogEnabled = true;
          mesh.isPickable = false;
          mesh.useVertexColors = false;
        });
      }

      if (greens instanceof TransformNode) {
        greens.getChildMeshes().forEach((mesh) => {
          (mesh.material as PBRMaterial).albedoColor = new Color3(0.03, 0.06, 0.03);
          (mesh.material as PBRMaterial).metallic = 0;
          (mesh.material as PBRMaterial).roughness = 100;
          (mesh.material as PBRMaterial).reflectivityColor = new Color3(0, 0, 0);
          (mesh.material as PBRMaterial).reflectionColor = new Color3(0, 0, 0);
          mesh.material.fogEnabled = true;
          mesh.isPickable = false;
          mesh.useVertexColors = false;
        });
      }

      if (typeof callback === 'function') {
        callback();
      }
    });
  }

  applyWaterMaterial(mesh: AbstractMesh) {
    if (mesh == null) return;
    (mesh.material as PBRMaterial).albedoColor = new Color3(0.17, 0.22, 0.27);
    mesh.useVertexColors = false;
    mesh.receiveShadows = true;
    mesh.material.fogEnabled = true;
    mesh.isPickable = false;
  }

  closeInfoPanel() {
    if (!this.isInfoPanelOpen()) return;
    this.resetSelectedBuilding();
    this.isInfoPanelOpen.set(false);
    this.smartCityService.isDependencyPickMode.set(false);
    this.pickedDependencies.set([]);
    this.processMeshes();
  }

  resetSelectedBuilding() {
    if (this.selectedMesh) {
      this.selectedMesh.renderOutline = false;
      this.selectedMesh.edgesColor = new Color4(0.8, 0.8, 0.8, 0.2);
      this.selectedMesh.material = this.meshOriginalMaterials.get(this.selectedMesh) || null;
      this.selectedBuildingDetails = null;
      this.selectedMesh = null;
      this.selectedBuildingDetails = null;
      this.processMeshes();
    }
  }

  toggleMap() {
    this.isMapOpen = !this.isMapOpen;
    if (this.isMapOpen) {
      this.scene.activeCameras = [this.camera, this.guiCamera, this.mapCamera];
      this.mapBackgroundBox.isVisible = true;
    } else {
      this.scene.activeCameras = [this.camera, this.guiCamera];
      this.mapBackgroundBox.isVisible = false;
    }
  }

  ngOnDestroy() {
    this.engine?.dispose();
  }
}
