import { BaseModel } from '../shared/base.model';

export class CreateAfterActionReportModel extends BaseModel {
  templateFileId?: string;
  aarTitle?: string;
  aarPromptOverrideId?: string;
  isTestRun?: boolean;
  useAI?: boolean;
  includeAppendixDump?: boolean;
  includeAttackObjectiveAnalysis?: boolean;
  includeBlueteamPerformanceAnalysis?: boolean;
  includeCTFResults?: boolean;
  includeParticipantFeedback?: boolean;
  commentVerbosity?: number;

  constructor(data?: any) {
    super(data);
  }
}

export class AfterActionReportModel extends BaseModel {
  exerciseId: string;
  submittedByUserId?: string;
  status: string;
  promptOverride?: string;
  aarId: string;
  aarTemplateDocFileId?: string;
  aarTitle?: string;
  composedAarDocFileId?: string;
  error?: string;
  exerciseName: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  progress?: number;
  totalProgress?: number;

  constructor(data?: any) {
    super(data);
  }
}

export class AIFFileModel extends BaseModel {
  id: string;
  name: string;

  constructor(data?: any) {
    super(data);
  }
}
