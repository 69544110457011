<mat-card class="info-panel">
  <button
    class="close-info-panel-btn"
    (click)="closeInfoPanel()"
    mat-icon-button
    aria-label="close infopanel"
  >
    <mat-icon>close</mat-icon>
  </button>
  @if (selectedBuildingDetails && !isEditMode) {
    <div class="building-type-row">
      <span>({{ selectedMeshId }})</span>
      <button (click)="initEditMode()" class="edit-building-btn" mat-icon-button>
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    @if (
      selectedTarget?.status === TargetStatus.NOT_AVAILABLE ||
      selectedTask?.status === TaskStatus.NOT_STARTED ||
      selectedTask?.status === TaskStatus.ABANDONED ||
      selectedTask?.status === TaskStatus.DEPENDENCIES_UNSOLVED ||
      selectedTask?.status === TaskStatus.LOCKED
    ) {
      @if (selectedBuildingDetails.media?.notAvailable) {
        @if (selectedBuildingDetails.media.notAvailable.type === StatusMediaType.IMAGE) {
          <img
            class="media"
            data-testid="img"
            [src]="getMediaUrl(selectedBuildingDetails.media.notAvailable.id) | isaSafeUrl"
            alt=""
          />
        } @else {
          <video
            class="media"
            controls
            autoplay
            width="400"
            loop
            [muted]="'muted'"
            data-testid="video"
          >
            <source
              [src]="getMediaUrl(selectedBuildingDetails.media.notAvailable.id) | isaSafeUrl"
              type="video/mp4"
            />
          </video>
        }
      } @else {
        <ng-container
          *ngTemplateOutlet="cityImageTemplate; context: { city: selectedCity }"
        ></ng-container>
      }
    } @else if (
      selectedTarget?.status === TargetStatus.COMPROMISED ||
      selectedTask?.status === TaskStatus.PARTLY_SOLVED ||
      selectedTask?.status === TaskStatus.VALIDATING ||
      selectedTask?.status === TaskStatus.IN_PROGRESS
    ) {
      @if (selectedBuildingDetails.media?.compromised) {
        @if (selectedBuildingDetails.media.compromised.type === StatusMediaType.IMAGE) {
          <img
            class="media"
            data-testid="img"
            [src]="getMediaUrl(selectedBuildingDetails.media.compromised.id) | isaSafeUrl"
            alt=""
          />
        } @else {
          <video
            class="media"
            controls
            autoplay
            width="400"
            loop
            [muted]="'muted'"
            data-testid="video"
          >
            <source
              [src]="getMediaUrl(selectedBuildingDetails.media.compromised.id) | isaSafeUrl"
              type="video/mp4"
            />
          </video>
        }
      } @else {
        <ng-container
          *ngTemplateOutlet="cityImageTemplate; context: { city: selectedCity }"
        ></ng-container>
      }
    } @else if (
      selectedTarget?.status === TargetStatus.GOOD || selectedTask?.status === TaskStatus.SOLVED
    ) {
      @if (selectedBuildingDetails.media?.good) {
        @if (selectedBuildingDetails.media.good.type === StatusMediaType.IMAGE) {
          <img
            class="media"
            data-testid="img"
            [src]="getMediaUrl(selectedBuildingDetails.media.good.id) | isaSafeUrl"
            alt=""
          />
        } @else {
          <video
            class="media"
            controls
            autoplay
            width="400"
            loop
            [muted]="'muted'"
            data-testid="video"
          >
            <source
              [src]="getMediaUrl(selectedBuildingDetails.media.good.id) | isaSafeUrl"
              type="video/mp4"
            />
          </video>
        }
      } @else {
        <ng-container
          *ngTemplateOutlet="cityImageTemplate; context: { city: selectedCity }"
        ></ng-container>
      }
    }

    <div>
      <div class="building-type-row">
        {{ selectedBuildingDetails.type }}
        @if (selectedTarget?.isUnderAttack === true) {
          <i class="fas fa-bolt fa-fade under-attack" aria-hidden="true"></i>
          <span>{{ 'ui.enums.UNDER_ATTACK' | translate }}</span>
        }
      </div>
      <div class="disable-uppercase tone-down">
        {{ selectedBuildingDetails?.address }}
      </div>
    </div>
    @if (selectedTarget) {
      <div class="target">
        <div class="thin">{{ 'ui.smartCity.target' | translate }}</div>
        <div>{{ selectedTarget?.name }}</div>
        @for (targetCheck of selectedTarget?.targetChecks; track targetCheck.id) {
          <div class="target-check disable-uppercase">
            <span
              class="target-check-state"
              [ngClass]="{
                'target-check-state__good': targetCheck.status === TargetStatus.GOOD,
                'target-check-state__compromised': targetCheck.status === TargetStatus.COMPROMISED,
                'target-check-state__not-available':
                  targetCheck.status === TargetStatus.NOT_AVAILABLE
              }"
            >
              @if (targetCheck.status === TargetStatus.GOOD) {
                <i
                  class="fas fa-check-circle"
                  aria-hidden="true"
                  [matTooltip]="'ui.enums.GOOD' | translate"
                ></i>
              } @else if (targetCheck.status === TargetStatus.COMPROMISED) {
                <i
                  class="fas fa-exclamation-circle"
                  aria-hidden="true"
                  [matTooltip]="'ui.enums.COMPROMISED' | translate"
                ></i>
              } @else if (targetCheck.status === TargetStatus.NOT_AVAILABLE) {
                <i
                  class="fas fa-minus-circle"
                  aria-hidden="true"
                  [matTooltip]="'ui.enums.NOT_AVAILABLE' | translate"
                ></i>
              }
            </span>
            {{ targetCheck.name }}
          </div>
        }
      </div>
    }
    @if (selectedTask) {
      <div>
        <div class="thin">{{ 'ui.smartCity.task' | translate }}</div>
        <div class="task">
          <div>
            {{ selectedTask.title }} ({{ selectedTask.score }}
            {{ 'ui.smartCity.points' | translate }})
          </div>
          <div
            [ngClass]="{
              'good-color': selectedTask.status === TaskStatus.SOLVED,
              'compromised-color':
                selectedTask.status === TaskStatus.PARTLY_SOLVED ||
                selectedTask.status === TaskStatus.VALIDATING ||
                selectedTask.status === TaskStatus.IN_PROGRESS,
              'not-available-color':
                selectedTask.status === TaskStatus.ABANDONED ||
                selectedTask.status === TaskStatus.NOT_STARTED ||
                selectedTask.status === TaskStatus.DEPENDENCIES_UNSOLVED ||
                selectedTask?.status === TaskStatus.LOCKED
            }"
            class="task-status"
          >
            {{ selectedTask.status | isaReplaceUnderScores }}
          </div>
        </div>
      </div>
    }

    @if (selectedBuildingDetails?.dependencies?.length) {
      <div>
        <div class="thin">{{ 'ui.smartCity.dependencies' | translate }}</div>
        <mat-chip-set aria-label="Dependencies">
          @for (dependency of selectedBuildingDetails.dependencies; track dependency) {
            <mat-chip>{{ dependency }}</mat-chip>
          }
        </mat-chip-set>
      </div>
    }
  }
  <ng-template #cityImageTemplate let-city="city">
    <img [src]="city === 'Tartu' ? 'assets/img/tartu.png' : 'assets/img/baltimore.png'" />
  </ng-template>

  @if (isEditMode) {
    {{ 'ui.smartCity.infoPanel.editBuilding' | translate: { id: selectedMeshId } }}
    <form class="building-form" [formGroup]="form">
      <mat-form-field>
        <mat-label>{{ 'ui.smartCity.address' | translate }}</mat-label>
        <input formControlName="address" matInput />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'ui.smartCity.type' | translate }}</mat-label>
        <input formControlName="type" matInput />
      </mat-form-field>
      @if (targets) {
        <mat-form-field>
          <mat-label>{{ 'ui.smartCity.target' | translate }}</mat-label>
          <input
            type="text"
            aria-label="Target"
            matInput
            formControlName="target"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayNameFn">
            @for (target of filteredTargets | async; track target.id) {
              <mat-option [value]="target">{{ target.name }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      }
      @if (ctfTasks) {
        <mat-form-field>
          <mat-label>{{ 'ui.smartCity.task' | translate }}</mat-label>
          <input
            type="text"
            aria-label="Ctf task"
            matInput
            formControlName="task"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTaskFn">
            @for (task of filteredTasks | async; track task.id) {
              <mat-option [value]="task">{{ task.title }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      }
      <mat-form-field>
        <mat-label>{{ 'ui.smartCity.dependencies' | translate }}</mat-label>
        <mat-chip-grid #chipGrid aria-label="Enter dependencies">
          @for (dependency of dependencies.controls; track dependency; let i = $index) {
            <mat-chip-row
              (removed)="remove(i)"
              [editable]="true"
              [aria-description]="
                'ui.smartCity.infoPanel.pressEnterToEdit' | translate: { value: dependency.value }
              "
            >
              {{ dependency.value }}
              <button
                matChipRemove
                [attr.aria-label]="
                  'ui.smartCity.infoPanel.removeValue' | translate: { value: dependency.value }
                "
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          }
          <input
            [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="add($event)"
          />
        </mat-chip-grid>
      </mat-form-field>
      <mat-slide-toggle (change)="togglePickMode()" class="pick-toggle">
        {{ 'ui.smartCity.infoPanel.pickDependencies' | translate }}
      </mat-slide-toggle>
      <div class="hint-wrapper mat-mdc-form-field-hint-wrapper">
        <mat-hint>{{ 'ui.smartCity.infoPanel.pickDependenciesHint' | translate }}</mat-hint>
      </div>

      @if (filesMetaData) {
        <mat-form-field>
          <mat-label>{{ 'ui.smartCity.infoPanel.goodMedia' | translate }}</mat-label>
          <input
            type="text"
            aria-label="File"
            matInput
            formControlName="goodMediaId"
            [matAutocomplete]="autoGood"
          />
          <mat-autocomplete #autoGood="matAutocomplete" [displayWith]="displayFileNameFn">
            @for (file of filteredGoodMediaFiles | async; track file.id) {
              <mat-option [value]="file.id">{{ file.name }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'ui.smartCity.infoPanel.compromisedMedia' | translate }}</mat-label>
          <input
            type="text"
            aria-label="File"
            matInput
            formControlName="compromisedMediaId"
            [matAutocomplete]="autoCompromised"
          />
          <mat-autocomplete #autoCompromised="matAutocomplete" [displayWith]="displayFileNameFn">
            @for (file of filteredCompromisedMediaFiles | async; track file.id) {
              <mat-option [value]="file.id">{{ file.name }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'ui.smartCity.infoPanel.notAvailableMedia' | translate }}</mat-label>
          <input
            type="text"
            aria-label="File"
            matInput
            formControlName="notAvailableMediaId"
            [matAutocomplete]="autoNotAvailable"
          />
          <mat-autocomplete #autoNotAvailable="matAutocomplete" [displayWith]="displayFileNameFn">
            @for (file of filteredNotAvailableMediaFiles | async; track file.id) {
              <mat-option [value]="file.id">{{ file.name }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      }
      <mat-checkbox formControlName="applyForAllTeams">{{
        'ui.smartCity.infoPanel.applyForAllTeams' | translate
      }}</mat-checkbox>
    </form>
    <div class="action-buttons">
      <button (click)="saveBuildingData()" mat-raised-button color="primary">
        {{ 'cybexer.shared.save' | translate }}
      </button>
      <button (click)="closeInfoPanel()" mat-raised-button>
        {{ 'cybexer.shared.cancel' | translate }}
      </button>
    </div>
  }
</mat-card>
