<div class="exercise-aar-dialog">
  @if (exercise) {
    <h2 matDialogTitle class="exercise-name">
      <span class="exercise-name-text">{{
        'ui.aar.dialogTitle' | translate: { exerciseName: exercise.name }
      }}</span>
    </h2>
    <mat-dialog-content>
      <form [formGroup]="form">
        <cybexer-row>
          @if (!addingTemplate && renaming) {
            <mat-form-field class="file-name-field">
              <mat-label>{{ 'ui.fileManager.fileName' | translate }}</mat-label>
              <input type="text" matInput formControlName="fileName" />
            </mat-form-field>
          } @else {
            <mat-form-field class="full-width">
              <mat-label>{{ 'ui.aar.selectTemplate' | translate }}</mat-label>
              <mat-select
                class="template-dropdown"
                formControlName="selectedTemplate"
                (selectionChange)="selectTemplate($event.value)"
              >
                @for (template of existingTemplates; track template) {
                  <mat-option class="template-option disable-uppercase" [value]="template">
                    {{ template.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
          @if (selectedTemplate?.id) {
            @if (!selectedTemplate.id.startsWith('DEFAULT_')) {
              @if (!renaming) {
                <button
                  mat-icon-button
                  (click)="renaming = true"
                  [matTooltip]="'ui.rename' | translate"
                >
                  <mat-icon>edit</mat-icon>
                </button>
              } @else {
                <button
                  mat-icon-button
                  (click)="renameTemplate()"
                  [matTooltip]="'ui.save' | translate"
                >
                  <mat-icon>done</mat-icon>
                </button>
              }
              <cybexer-confirm-action>
                <button
                  mat-icon-button
                  (click)="deleteTemplate()"
                  [matTooltip]="'ui.delete' | translate"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </cybexer-confirm-action>
            }
            <button
              mat-icon-button
              (click)="downloadTemplate()"
              [matTooltip]="'ui.download' | translate"
            >
              <mat-icon>download</mat-icon>
            </button>
          }
          @if (addingTemplate) {
            <button
              mat-icon-button
              (click)="addingTemplate = !addingTemplate"
              [matTooltip]="'ui.close' | translate"
            >
              <mat-icon>close</mat-icon>
            </button>
          } @else {
            <button
              mat-icon-button
              (click)="addingTemplate = !addingTemplate"
              [matTooltip]="'ui.aar.addTemplate' | translate"
            >
              <mat-icon>add</mat-icon>
            </button>
          }
        </cybexer-row>

        @if (addingTemplate) {
          <cybexer-card>
            <mat-label>{{ 'ui.aar.uploadFile' | translate }}</mat-label>
            <div class="drag-area" cybexerFileDragDrop (filesChangeEmiter)="setFile($event[0])">
              <label class="file-label" for="fileInput">
                <div class="attachment-btn">
                  <mat-icon>attach_file</mat-icon>
                </div>
                @if (!file) {
                  <span class="disable-uppercase">{{
                    'ui.aar.dragAndDropOrClickHereToUploadFiles' | translate
                  }}</span>
                }
              </label>
              <input
                [disabled]="uploadInitiated"
                (change)="setFile($event.target.files[0])"
                hidden
                id="fileInput"
                type="file"
                accept=".doc,.docx,.dotx"
              />
            </div>

            @if (file) {
              <div class="file-list">
                <mat-form-field class="file-name-field">
                  <mat-label>{{ 'ui.fileManager.fileName' | translate }}</mat-label>
                  <input type="text" matInput formControlName="fileName" />
                </mat-form-field>
                <span class="disable-uppercase tone-down disable-uppercase">
                  ({{ file.size | cybexerFileSize }})
                </span>
                @if (!response?.error && loading) {
                  <mat-progress-bar mode="determinate" [value]="fileProgress"></mat-progress-bar>
                }
                @if (response?.error) {
                  <div class="file-error">
                    {{ response.error?.errors ? response.error.errors[0] : response.error.message }}
                  </div>
                }
                @if (response?.path) {
                  <div class="file-uploaded">
                    <mat-icon>check</mat-icon>
                    {{ 'ui.shared.fileUploaded' | translate }}
                  </div>
                }
              </div>
            }

            @if (!uploadInitiated) {
              <button
                type="button"
                (click)="uploadFiles()"
                [disabled]="!file || loading || form.invalid"
                mat-raised-button
                color="primary"
              >
                {{ 'ui.shared.upload' | translate }}
              </button>
            }
          </cybexer-card>
        } @else {
          @if (loading) {
            <cybexer-progress-spinner
              [spinnerText]="'cybexer.shared.loading' | translate"
            ></cybexer-progress-spinner>
          } @else {
            <mat-form-field class="aar-title-field full-width">
              <mat-label>{{ 'ui.aar.title' | translate }}</mat-label>
              <input type="text" matInput formControlName="aarTitle" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'ui.aar.commentVerbosity' | translate }}</mat-label>
              <input type="number" matInput formControlName="commentVerbosity" min="0" max="4" />
            </mat-form-field>
            <cybexer-flex direction="vertical" gap="2">
              <mat-checkbox formControlName="useAI">
                <i class="fas fa-robot fa-fw" aria-hidden="true"></i>
                {{ 'ui.aar.useAI' | translate }}
              </mat-checkbox>
              <mat-checkbox formControlName="includeAttackObjectiveAnalysis">
                <i class="fas fa-user-secret fa-fw" aria-hidden="true"></i>
                {{ 'ui.aar.includeAttackObjectiveAnalysis' | translate }}
              </mat-checkbox>
              <mat-checkbox formControlName="includeBlueteamPerformanceAnalysis" hidden>
                <i class="fas fa-user fa-fw" aria-hidden="true"></i>
                {{ 'ui.aar.includeBlueteamPerformanceAnalysis' | translate }}
              </mat-checkbox>
              <mat-checkbox formControlName="includeCTFResults">
                <i class="fas fa-flag fa-fw" aria-hidden="true"></i>
                {{ 'ui.aar.includeCTFResults' | translate }}
              </mat-checkbox>
              <mat-checkbox formControlName="includeParticipantFeedback" hidden>
                <i class="fas fa-comment fa-fw" aria-hidden="true"></i>
                {{ 'ui.aar.includeParticipantFeedback' | translate }}
              </mat-checkbox>
              <mat-checkbox formControlName="includeAppendixDump">
                <i class="fas fa-list fa-fw" aria-hidden="true"></i>
                {{ 'ui.aar.includeAppendixDump' | translate }}
              </mat-checkbox>
            </cybexer-flex>
          }

          <cybexer-flex horizontalAlign="right" gap="2">
            <cybexer-confirm-action>
              <button
                type="button"
                mat-raised-button
                [disabled]="loading || form.invalid"
                (click)="createAar(true)"
              >
                <i class="fas fa-file-word" aria-hidden="true"></i>
                {{ 'ui.aar.createAarTestRun' | translate }}
              </button>
            </cybexer-confirm-action>
            <cybexer-confirm-action>
              <button
                type="button"
                mat-raised-button
                color="primary"
                [disabled]="loading || form.invalid"
                (click)="createAar()"
              >
                <i class="fas fa-file-word" aria-hidden="true"></i>
                {{ 'ui.aar.createAar' | translate }}
              </button>
            </cybexer-confirm-action>
          </cybexer-flex>

          @if (existingAars?.length) {
            <div class="spacer"></div>
            <mat-label>{{ 'ui.aar.existingAars' | translate }}</mat-label>
            <cybexer-simple-table
              class="aars-table"
              [tableData]="existingAars"
              [columns]="aarColumns"
            >
            </cybexer-simple-table>
          }
        }
      </form>
    </mat-dialog-content>
    <cybexer-flex class="footer" horizontalAlign="justify" [gap]="1">
      <cybexer-flex
        verticalAlign="center"
        [matTooltip]="
          data.promptOverride?.id
            ? ('ui.reports.activeOverride' | translate: { promptName: data.promptOverride?.name })
            : ''
        "
      >
        <i class="fas fa-robot" aria-hidden="true"></i>
        {{ data.promptOverride?.name || 'ui.aar.defaultPrompts' | translate }}
      </cybexer-flex>
      <mat-dialog-actions align="end">
        @if (!loading) {
          <button mat-button matDialogClose>{{ 'ui.close' | translate }}</button>
        } @else {
          <cybexer-confirm-action>
            <button mat-button matDialogClose>{{ 'ui.cancel' | translate }}</button>
          </cybexer-confirm-action>
        }
      </mat-dialog-actions>
    </cybexer-flex>
  }
</div>
