import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LanguageUtils } from '../../shared/language.utils';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TranslationService implements TranslateLoader {
  private static TRANSLATION_API_PATH = 'api/translation';

  constructor(private http: HttpClient) {}

  getTranslation(lang: string, namespaces?: string[]): Observable<any> {
    return this.http
      .get(
        `${TranslationService.TRANSLATION_API_PATH}/${lang}/${namespaces ? namespaces : LanguageUtils.NAMESPACES}`
      )
      .pipe(
        catchError(() => {
          const fallbackTranslations = {
            'ui.error.ENTITY_NOT_FOUND': 'Entity not found',
            'ui.error.ATTEMPTING_RECONNECT': 'Attempting to reconnect to your session...',
            'ui.error.ERROR_OCCURRED': 'Error occurred',
            'ui.error.REFRESH_PAGE': 'Please refresh the page if any problems persist',
            'ui.error.TRY_AGAIN': 'Please try again later',
            'ui.error.INTERNAL_ERROR': 'Oops, we managed to produce error 500',
            'ui.error.INVALID_REQUEST': 'Request is not valid',
            'ui.error.UNAUTHENTICATED_REQUEST': 'Request is not authenticated',
            'ui.error.UNAUTHORIZED_REQUEST': 'Request is not authorized',
          };
          return of(fallbackTranslations);
        })
      );
  }
}
