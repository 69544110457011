import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import {
  AfterActionReportModel,
  AIFFileModel,
  CreateAfterActionReportModel,
} from '../../models/gamenet/after-action-report.model';

@Injectable()
export class AfterActionReportService {
  private static readonly TEMPLATE_BASE_URL = `api/after-action-report-templates`;

  constructor(private http: HttpClient) {}

  getAarTemplates(): Observable<AIFFileModel[]> {
    return this.http.get<AIFFileModel[]>(AfterActionReportService.TEMPLATE_BASE_URL).pipe(
      map((dataArray) => {
        return dataArray.map((data) => new AIFFileModel(data));
      })
    );
  }

  uploadTemplateFile(
    content: ArrayBuffer,
    type: string,
    name?: string
  ): Observable<HttpEvent<string>> {
    const formData = new FormData();
    formData.append('file', new Blob([content], { type }));
    formData.append('type', type);
    formData.append('name', name);

    return this.http.put(AfterActionReportService.TEMPLATE_BASE_URL, formData, {
      reportProgress: true,
      observe: 'events',
      responseType: 'text',
    });
  }

  renameAarTemplate(fileId: string, fileName: string): Observable<boolean> {
    return this.http
      .post<boolean>(AfterActionReportService.TEMPLATE_BASE_URL + `/${fileId}/rename`, fileName)
      .pipe(map(() => true));
  }

  deleteAarTemplate(fileId: string): Observable<boolean> {
    return this.http
      .delete<boolean>(AfterActionReportService.TEMPLATE_BASE_URL + `/${fileId}`)
      .pipe(map(() => true));
  }

  getAars(exerciseId: string): Observable<AfterActionReportModel[]> {
    return this.http.get<AfterActionReportModel[]>(this.getBaseUrl(exerciseId)).pipe(
      map((dataArray) => {
        return dataArray.map((data) => new AfterActionReportModel(data));
      })
    );
  }

  downloadAarTemplate(id: string, fileName: string): void {
    saveAs(AfterActionReportService.TEMPLATE_BASE_URL + `/${id}/download`, fileName);
  }

  downloadAar(exerciseId: string, id: string, fileName: string): void {
    saveAs(this.getBaseUrl(exerciseId) + `/${id}/download`, fileName);
  }

  createAar(exerciseId: string, aar: CreateAfterActionReportModel): Observable<string> {
    return this.http.put(this.getBaseUrl(exerciseId), aar, {
      responseType: 'text',
    });
  }

  private getBaseUrl(exerciseId: string): string {
    return `api/exercises/${exerciseId}/after-action-reports`;
  }
}
