import { VersusAttackStatus } from '../../../shared';
import { BaseModel } from '../../shared/base.model';
import { CTFHint } from '../exercise.model';
import { TargetConsoleData } from '../target.model';
import { IndividualStartStop } from './ctf-mission-status.model';
import { CTFTaskStatus } from './ctf-task-status.model';

export class CTFMission extends BaseModel {
  teamCount: number;
  endTime?: Date;
  missionEndTime?: Date;
  openedTasksLimit: number;
  tasksByCategory: CTFCategoryDTO[];
  individualStartStop?: IndividualStartStop;
  targets: CTFTargetData[];
  showMissionBoardConsole: boolean;

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('tasksByCategory')) {
      this.tasksByCategory = data.tasksByCategory.map((category) => new CTFCategoryDTO(category));
    }
    if (data.hasOwnProperty('endTime')) {
      this.endTime = new Date(data.endTime);
    }
    if (data.hasOwnProperty('missionEndTime')) {
      this.missionEndTime = new Date(data.missionEndTime);
    }
    if (data.hasOwnProperty('individualStartStop')) {
      this.individualStartStop = new IndividualStartStop(data.individualStartStop);
    }
    if (data && data.hasOwnProperty('targets')) {
      this.targets = data.targets.map((target) => new CTFTargetData(target));
    }
  }

  get openedTaskCount(): number {
    return this.tasksByCategory.reduce((count, categoryTasks) => {
      return (
        count +
        categoryTasks.tasks.filter((task) => task.status === CTFTaskStatus.IN_PROGRESS).length
      );
    }, 0);
  }

  get tasks(): CTFTaskDTO[] {
    return this.tasksByCategory.reduce((tasks, categoryTasks) => {
      return tasks.concat(categoryTasks.tasks);
    }, []);
  }
}

export class CTFCategoryDTO extends BaseModel {
  category: string;
  name: string;
  tasks: CTFTaskDTO[];

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('tasks')) {
      this.tasks = data.tasks.map((task) => new CTFTaskDTO(this.category, task));
    }
  }
}

export class CTFTaskDTO extends BaseModel {
  id: string;
  type: CTFTaskType;
  title: string;
  category: string;
  score?: number;
  status: CTFTaskStatus;
  teamsSolved: number;
  usedHintPoints: number;
  availableHints: number;
  requiredTasks: RequiredTask[];
  niceIds: NiceIds;
  mitreTags: MitreTags;
  isAnswerCrossUsageDetected: boolean;
  targets: CTFTargetData[];
  successfulAttackLimitPerTeam?: number;
  difficulty?: number;

  constructor(category: string, data?) {
    super(data);
    this.category = category;
    if (data && data.hasOwnProperty('requiredTasks')) {
      this.requiredTasks = data.requiredTasks.map((dt) => new RequiredTask(dt));
    }

    if (data && data.hasOwnProperty('targets')) {
      this.targets = data.targets.map((target) => new CTFTargetData(target));
    }
  }
}

export class NiceIds extends BaseModel {
  taskIds: string[];
  knowledgeIds: string[];
  skillIds: string[];
  abilityIds: string[];

  constructor(data?) {
    super(data);
  }
}

export class MitreTags extends BaseModel {
  tags: string[];

  constructor(data?) {
    super(data);
  }
}

export class MitreDisplayObject extends BaseModel {
  tag: string;
  name: string;
  description: string;
  linkUrl: string;
}

export enum CTFTaskType {
  SINGLE_ANSWER = 'SINGLE_ANSWER',
  MULTIPLE_ANSWERS = 'MULTIPLE_ANSWERS',
  GMA_VALIDATION = 'GMA_VALIDATION',
  FREE_FORM = 'FREE_FORM',
  VERSUS = 'VERSUS',
}

export class RequiredTask extends BaseModel {
  id: string;
  title: string;

  constructor(data?) {
    super(data);
  }
}

export class CTFTargetData extends BaseModel {
  targetName: string;
  vmId?: string;
  consoleData?: TargetConsoleData;

  constructor(data?) {
    super(data);

    if (data && data.hasOwnProperty('consoleData')) {
      this.consoleData = new TargetConsoleData(data.consoleData);
    }
  }
}

export class VersusTaskTeamDTO extends BaseModel {
  id: string;
  name: string;
  status: TaskTeamStatus;
  attacksRemaining?: number;
  availableFrom?: Date;
  previousAttacks: VersusAttackDetails[];

  constructor(data?) {
    super(data);

    if (data && data.hasOwnProperty('previousAttacks')) {
      this.previousAttacks = data.previousAttacks.map(
        (previousAttack) => new VersusAttackDetails(previousAttack)
      );
    }
  }
}

export enum TaskTeamStatus {
  AVAILABLE = 'AVAILABLE',
  UNDER_ATTACK = 'UNDER_ATTACK',
  COMPLETED = 'COMPLETED',
  COOL_DOWN = 'COOL_DOWN',
}

export class VersusAttackDetails extends BaseModel {
  startTime: Date;
  duration: number;
  result: VersusAttackStatus;

  constructor(data?) {
    super(data);
  }
}

export class CTFTaskDetailsDTO extends BaseModel {
  id: string;
  title: string;
  question: string;
  description: string;
  category: string;
  score: number;
  hintCount: number;
  hints: CTFHint[];
  usedHintIds: string[];
  nextHintPenalty?: number;
  status: CTFTaskStatus;
  timeSpentMs?: number;
  answer?: string;
  taskAbandonPenalty: number;
  penaltyPoints: number;
  submissions?: CTFSubmission[];
  feedback?: CTFTaskFeedback[];
  validatorOutput?: string;
  isInputRequired?: boolean;
  teamName: string;
  teamUnderAttack?: VersusTaskTeamDTO;
  attackAbandonPenalty?: number;
  versusTargetInfo?: string;
  versusAttackEndTime?: Date;
  difficulty?: number;
  estimatedTime?: number;
  solution?: string;

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('hints')) {
      this.hints = data.hints.map((hint) => new CTFHint(hint));
    }

    if (data && data.hasOwnProperty('submissions')) {
      this.submissions = data.submissions.map((submission) => new CTFSubmission(submission));
    }

    if (data && data.hasOwnProperty('feedback')) {
      this.feedback = data.feedback.map((feedback) => new CTFTaskFeedback(feedback));
    }

    if (data && data.hasOwnProperty('versusAttackEndTime')) {
      this.versusAttackEndTime = new Date(data.versusAttackEndTime);
    }
  }
}

export class OrganizedCTFTaskDetailsDTO extends BaseModel {
  id: string;
  title: string;
  usedHintCount: number;
  avgTimeSpentMs: number;
  tasks: CTFTaskDetailsOverviewDTO[];

  constructor(data) {
    super(data);

    if (data && data.hasOwnProperty('tasks')) {
      this.tasks = data.tasks.map((task) => new CTFTaskDetailsOverviewDTO(task));
    }
  }
}

export class CTFTaskDetailsOverviewDTO extends BaseModel {
  teamId: string;
  teamName: string;
  title: string;
  score: number;
  usedHintCount: number;
  falseAnswerCount: number;
  submissionCount: number;
  lastSubmissionAnswer?: string;
  lastSubmissionTimestamp?: Date;
  status: CTFTaskStatus;
  timeSpentMs?: number;
  penaltyPoints: number;
  crossUsageTeams: string[];

  constructor(data?) {
    super(data);
  }
}

export class CTFSubmission extends BaseModel {
  timestamp: Date;
  isCorrect?: boolean;
  teamName: string;
  userName: string;
  answer?: string;
  score?: number;
  feedback?: string;
  validatorOutput?: string;
  answerCrossUsageTeamNames?: string[];

  constructor(data?) {
    super(data);
    if (data.hasOwnProperty('timestamp')) {
      this.timestamp = new Date(data.timestamp);
    }
  }
}

export class CTFTaskFeedback extends BaseModel {
  timestamp: Date;
  teamName: string;
  userName: string;
  feedback: string;

  constructor(data?) {
    super(data);
  }
}

enum TaskRating {
  VERY_EASY = 'Very Easy',
  EASY = 'Easy',
  MEDIUM = 'Medium',
  HARD = 'Hard',
  VERY_HARD = 'Very Hard',
}

export const TASK_RATINGS: Map<string, number> = new Map<string, number>([
  [TaskRating.VERY_EASY, 0],
  [TaskRating.EASY, 20],
  [TaskRating.MEDIUM, 40],
  [TaskRating.HARD, 60],
  [TaskRating.VERY_HARD, 80],
]);
