import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  skip,
  switchMap,
  tap,
} from 'rxjs/operators';
import { SituationReportsDatasource } from '../../../datasources';
import {
  BlueTeam,
  Exercise,
  ReportFilter,
  ReportAIAssessment,
  SituationReportConfirmation,
  SituationReportsData,
} from '../../../models';
import {
  AuthenticationService,
  ExerciseService,
  IntervalService,
  PreferenceService,
  SituationReportsService,
} from '../../../services';
import {
  CONFIRMATION_STATUS,
  DEFAULT_PAGE_SIZE,
  EXERCISE_PERMISSIONS,
  FilterStateModel,
  FilterStateService,
  PAGE_SIZES,
  RoleCode,
  ROLES,
} from '../../../shared';
import { SituationReportConfirmDialogComponent } from './situation-report-confirm-dialog/situation-report-confirm-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  AIFabricPromptOverrideDialogComponent,
  AIFabricService,
  NamedEntity,
  PromptType,
} from '@cybexer/ngx-commons';

@UntilDestroy()
@Component({
  selector: 'isa-situation-reports',
  templateUrl: './situation-reports.component.html',
  styleUrls: ['./situation-reports.component.scss'],
})
export class SituationReportsComponent implements OnInit, AfterViewInit {
  static DEFAULT_LIMIT = 25;
  static DEFAULT_OFFSET = 0;

  filter$: Observable<Partial<FilterStateModel>>;

  exercise: Exercise;
  teams: BlueTeam[] = [];
  situationReportConfirmDialogRef: MatDialogRef<SituationReportConfirmDialogComponent>;
  textFilterFormControl = new UntypedFormControl();
  ROLES = ROLES;
  userRole: RoleCode;
  loading = true;
  isAdmin: boolean;
  isAIAvailable: boolean = false;
  isCheckedStatusIndeterminate: boolean = false;
  isGlobalChecked: boolean = false;
  checkedReportIds: Map<string, string> = new Map();
  shouldShowAITools: boolean = false;
  effectivePromptOverride?: NamedEntity;
  readonly EXERCISE_PERMISSIONS = EXERCISE_PERMISSIONS;

  readonly COLUMNS = {
    CHECKED: 'checked',
    TIMESTAMP: 'timestamp',
    TEAM_NAME: 'teamName',
    GRADE: 'grade',
    STATUS: 'status',
    WHITE_TEAM_MEMBER: 'whiteTeamMember',
    AI_ASSESSMENT_STATUS: 'aiAssessmentStatus',
    AI_GRADE: 'aiGrade',
  };
  readonly defaultColumns = [
    this.COLUMNS.TIMESTAMP,
    this.COLUMNS.TEAM_NAME,
    this.COLUMNS.GRADE,
    this.COLUMNS.STATUS,
    this.COLUMNS.WHITE_TEAM_MEMBER,
  ];
  readonly aiFeaturesColumns = [
    this.COLUMNS.CHECKED,
    ...this.defaultColumns,
    this.COLUMNS.AI_ASSESSMENT_STATUS,
    this.COLUMNS.AI_GRADE,
  ];
  readonly displayedColumns = [...this.defaultColumns];
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  dataSource: SituationReportsDatasource;

  protected readonly CONFIRMATION_STATUS = CONFIRMATION_STATUS;

  constructor(
    private dialog: MatDialog,
    private exerciseService: ExerciseService,
    private intervalService: IntervalService,
    private situationReportsService: SituationReportsService,
    private authenticationService: AuthenticationService,
    private aiFabricService: AIFabricService,
    private preferenceService: PreferenceService,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit() {
    this.isAdmin = this.authenticationService.currentUser.isAdmin;
    this.filter$ = this.filterStateService.filter$('team', 'pendingConfirmationOnly');
    this.dataSource = new SituationReportsDatasource(this.situationReportsService);

    this.paginator.pageSize =
      this.preferenceService.currentPreferences.defaultListSize || DEFAULT_PAGE_SIZE;

    this.aiFabricService
      .isAvailable()
      .pipe(untilDestroyed(this))
      .subscribe((isAvailable) => {
        this.isAIAvailable = isAvailable;
        this.updateAIToolsStatus();
      });

    this.exerciseService.activeExercise
      .pipe(
        tap((exercise: Exercise) => {
          this.aiFabricService.updateAvailability(
            { 'Exercise-Id': exercise?.id },
            `/api${AIFabricService.AI_FABRIC_PATH}-management`
          );
          this.exercise = exercise;
        }),
        filter((exercise: Exercise) => !!exercise),
        tap((exercise: Exercise) => {
          this.teams = this.exercise.blueTeams;
          this.userRole = this.authenticationService.getRole(exercise.id);
          if (!this.isAdmin) {
            this.isAdmin = this.authenticationService.currentUser.isGamenetAdmin(this.exercise.id);
          }
        }),
        switchMap((exercise) => {
          if (this.userRole === this.ROLES.BLUE) {
            this.filterStateService.setFilter('pendingConfirmationOnly', false);
            return this.exerciseService.getUserBlueTeam(exercise.id).pipe(untilDestroyed(this));
          }

          return of(null);
        }),
        tap((userBlueTeamId) => {
          if (userBlueTeamId != null) {
            this.filterStateService.setFilterIfEmptyOrDefault('team', userBlueTeamId);
          }

          this.updateEffectivePromptOverride();
          this.updateAIToolsStatus();
          this.loadReports();
        }),
        switchMap(() => this.intervalService.getWidgetRefreshInterval()),
        skip(1),
        untilDestroyed(this)
      )
      .subscribe(() => this.loadReportsSilently());

    // TODO: first call to filter needs to be investigated
    this.filter$.pipe(skip(1), untilDestroyed(this)).subscribe(() => {
      this.paginator.firstPage();
      this.loadReports();
    });

    this.dataSource.loading$.pipe(untilDestroyed(this)).subscribe(() => {
      this.dataSource.data.forEach((report) => {
        report.checked = this.checkedReportIds.has(report.reportId);
      });
      this.checkIndetermination();
    });
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(untilDestroyed(this)).subscribe(() => this.paginator.firstPage());

    this.textFilterFormControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.firstPage();
          this.loadReports();
        }),
        untilDestroyed(this)
      )
      .subscribe();

    // on sort or paginate events, load a new page
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadReports()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private loadReports(loadSilently: boolean = false): void {
    if (this.exercise) {
      this.dataSource.loadReports(this.exercise.id, this._createReportFilter(), loadSilently);
    }
  }

  private loadReportsSilently(): void {
    this.loadReports(true);
  }

  private _createReportFilter(): ReportFilter {
    const limit = this.paginator.pageSize || SituationReportsComponent.DEFAULT_LIMIT;
    const { team, pendingConfirmationOnly } = this.filterStateService.snapshot();
    const sortDirection = this.sort.direction;
    const sortOrder = sortDirection != null && !!sortDirection ? sortDirection : null;

    return new ReportFilter({
      offset:
        this.paginator.pageIndex == null
          ? SituationReportsComponent.DEFAULT_OFFSET
          : this.paginator.pageIndex * limit,
      limit: limit,
      unconfirmedOnlyFilter: pendingConfirmationOnly,
      teamIdFilter: team,
      textFilter: this.textFilterFormControl.value,
      sortColumn: sortOrder ? this.sort.active : null,
      sortOrder: sortOrder,
    });
  }

  openSituationReportConfirmDialog(situationReportData: SituationReportsData): void {
    this.situationReportConfirmDialogRef = this.dialog.open(SituationReportConfirmDialogComponent, {
      disableClose: false,
      data: {
        situationReportsData: situationReportData,
        exercise: this.exercise,
        aiFeaturesEnabled: this.isAIAvailable,
        promptOverrideId: this.effectivePromptOverride?.id,
      },
    });

    this.situationReportConfirmDialogRef
      .afterClosed()
      .subscribe((situationReportConfirmation: SituationReportConfirmation) => {
        if (situationReportConfirmation) {
          this.loadReports();
        }
        this.situationReportConfirmDialogRef = null;
      });
  }

  openAIFabricPromptOverrideDialog() {
    this.dialog.open(AIFabricPromptOverrideDialogComponent, {
      data: {
        aiFabricPromptOverrideService: this.aiFabricService,
        isLightTheme$: this.preferenceService.isLightTheme.asObservable(),
        currentSystemOverrideId: this.exercise?.promptOverride?.id,
        currentPreferenceOverrideId: this.preferenceService.currentPreferences?.promptOverride?.id,
        onPreferenceOverride: (selectedPromptOverride: NamedEntity) => {
          this.preferenceService
            .updatePreferences({
              promptOverride: selectedPromptOverride || null,
            })
            .pipe(first())
            .subscribe(() => this.updateEffectivePromptOverride());
        },
        isExerciseAdmin: this.isAdmin,
        editablePromptIds: [
          PromptType.SITUATION_REPORT_ASSESSMENT,
          PromptType.INCIDENT_REPORT_ASSESSMENT,
          PromptType.ATTACK_REPORT_ASSESSMENT,
          PromptType.CTF_TASK_REPORT_ASSESSMENT,
        ],
        mainPromptId: PromptType.SITUATION_REPORT_ASSESSMENT,
        userName: this.authenticationService.currentUser.username,
      },
    });
  }

  get pageSizes(): number[] {
    return PAGE_SIZES;
  }

  onCheckboxChange(event: MatCheckboxChange, data: SituationReportsData) {
    data.checked = event.checked;
    if (event.checked) this.checkedReportIds.set(data.reportId, data.teamId);
    else this.checkedReportIds.delete(data.reportId);
    this.checkIndetermination();
  }

  setGlobalCheckboxStatus(checked: boolean) {
    this.dataSource.data.forEach((report: any) => {
      report.checked = checked;
      if (checked) this.checkedReportIds.set(report.reportId, report.teamId);
      else this.checkedReportIds.delete(report.reportId);
    });
    this.isGlobalChecked = checked;
  }

  checkIndetermination() {
    const checkedAmount = this.dataSource.data?.filter((user: any) => user?.checked == true).length;
    this.isGlobalChecked = checkedAmount === this.dataSource.data.length;
    this.isCheckedStatusIndeterminate = !this.isGlobalChecked && checkedAmount !== 0;
  }

  updateAIToolsStatus() {
    this.shouldShowAITools =
      this.isAIAvailable && (this.userRole === this.ROLES.WHITE || this.isAdmin);
    this.displayedColumns.splice(
      0,
      this.displayedColumns.length,
      ...(this.shouldShowAITools ? this.aiFeaturesColumns : this.defaultColumns)
    );
  }

  clearSelected() {
    this.checkedReportIds.clear();
    this.dataSource.data.forEach((report: any) => {
      report.checked = false;
    });
    this.checkIndetermination();
  }

  sendToAIAssessment() {
    if (this.checkedReportIds.size === 0) return;
    const assessments = Array.from(this.checkedReportIds.entries()).map(
      ([reportId, teamId]) =>
        new ReportAIAssessment({
          reportId,
          teamId,
          promptOverrideId: this.effectivePromptOverride?.id,
        })
    );
    this.situationReportsService
      .createMultipleSituationReportAIAssessments(this.exercise.id, assessments)
      .pipe(first())
      .subscribe(() => {
        this.loadReports();
      });
    this.clearSelected();
  }

  private updateEffectivePromptOverride() {
    this.effectivePromptOverride =
      this.preferenceService.currentPreferences?.promptOverride || this.exercise?.promptOverride;
  }
}
