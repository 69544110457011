@if (!isResetInProgress) {
  <span
    class="status-circle"
    [ngClass]="{
      running: status === 'RUNNING',
      stopped: status === 'STOPPED',
      'not-started': status === 'NOT_STARTED',
      unpublished: status === 'UNPUBLISHED',
      scheduled: status === 'SCHEDULED'
    }"
  ></span>
} @else {
  <cybexer-progress-spinner></cybexer-progress-spinner>
}
