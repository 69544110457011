import { Component, OnInit } from '@angular/core';
import { AIFabricService, NotificationsService } from '@cybexer/ngx-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Exercise, ExerciseOverview, ExerciseStatus, ExerciseType, User } from '../../../models';
import { ExerciseResetSteps } from '../../../models/gamenet/exercise.model';
import { AuthenticationService, ExerciseService, IntervalService } from '../../../services';
import { EXERCISE_PERMISSIONS, FilterStateModel, FilterStateService } from '../../../shared';
import { ExerciseGroup, ExerciseGroupSave } from '../../../models/gamenet/exercise-group.model';
import { ExerciseGroupService } from '../../../services/gamenet/exercise-group.service';

@UntilDestroy()
@Component({
  selector: 'isa-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss'],
})
export class ExerciseComponent implements OnInit {
  filter$: Observable<Partial<FilterStateModel>>;
  exerciseGroups: ExerciseGroup[];
  ungroupedExercises: ExerciseOverview[];
  currentExercise: Exercise;
  selectedGroupIndex: number;
  exerciseResetSteps: Map<String, ExerciseResetSteps> = new Map();
  currentUser: User;
  exerciseResetStatusSubscriptions: Map<String, Subscription> = new Map();
  processingExerciseIds: string[] = [];
  ExerciseType = ExerciseType;
  loading = true;
  isModuleGroupEditingEnabled = false;
  isGroupCreationInProgress = false;
  isAiAvailable: boolean = false;
  readonly EXERCISE_PERMISSIONS = EXERCISE_PERMISSIONS;
  readonly ExerciseStatus = ExerciseStatus;

  constructor(
    private exerciseService: ExerciseService,
    private exerciseGroupService: ExerciseGroupService,
    private authenticationService: AuthenticationService,
    private intervalService: IntervalService,
    private notificationsService: NotificationsService,
    private aiFabricService: AIFabricService,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit() {
    this.filter$ = this.filterStateService.filter$('exerciseOrGroupName', 'runningExercisesOnly');
    this.currentUser = this.authenticationService.currentUser;
    this.intervalService
      .getWidgetRefreshInterval()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.isModuleGroupEditingEnabled) return;
        this.getExerciseList();
      });
    this.exerciseService.exerciseListChanged.pipe(untilDestroyed(this)).subscribe(() => {
      this.getExerciseList();
    });

    this.exerciseService.activeExercise.pipe(untilDestroyed(this)).subscribe((ex) => {
      this.currentExercise = ex;
      this.aiFabricService.updateAvailability(
        { 'Exercise-Id': ex?.id },
        `/api${AIFabricService.AI_FABRIC_PATH}-management`
      );
    });

    this.aiFabricService
      .isAvailable()
      .pipe(untilDestroyed(this))
      .subscribe((isAvailable) => {
        this.isAiAvailable = isAvailable;
      });
  }

  private getExerciseList(ungroupedOnly = false, compatibleExercises?: ExerciseOverview[]) {
    if (this.isGroupCreationInProgress) {
      return;
    }
    this.exerciseGroupService
      .getGroups()
      .pipe(untilDestroyed(this))
      .subscribe((exerciseGroups) => {
        const freshUngroupedExercises = exerciseGroups.find((it) => it.isUngrouped)?.exercises;
        this.ungroupedExercises = freshUngroupedExercises ? freshUngroupedExercises : [];
        if (ungroupedOnly) {
          const groupExercises = this.exerciseGroups[this.selectedGroupIndex].exercises;
          this.exerciseGroups[this.selectedGroupIndex].exercises = [];
          this.ungroupedExercises.push(...groupExercises, ...compatibleExercises);
        } else {
          const ungroupedIndex = exerciseGroups.findIndex((it) => it.isUngrouped);
          ungroupedIndex !== -1 ? exerciseGroups.splice(ungroupedIndex, 1) : null;
          this.exerciseGroups = exerciseGroups;
          this.exerciseGroups
            .flatMap((exerciseGroup) => exerciseGroup.exercises)
            .forEach((exercise) => {
              const exerciseResetStepsForEx = this.exerciseResetSteps.get(exercise.id) || null;
              if (!exercise.isResetInProgress && exerciseResetStepsForEx) {
                this.clearResetData(exercise.id);
              }
            });
        }
        this.loading = false;
      });
  }

  reloadScripts(exerciseId: string): void {
    const indexOf = this.processingExerciseIds.push(exerciseId) - 1;
    this.exerciseService
      .reloadScripts(exerciseId)
      .pipe(finalize(() => this.processingExerciseIds.splice(indexOf, 1)))
      .subscribe(() => {
        this.notificationsService.success('ui.exercise.scriptsReloaded');
      });
  }

  findExerciseOverviewById(id: string): ExerciseOverview {
    return this.ungroupedExercises.find((ex) => ex.id === id);
  }

  createExerciseGroup() {
    this.isGroupCreationInProgress = true;
    this.exerciseGroups.push(new ExerciseGroup({ name: undefined, exercises: [] }));
  }

  removeExerciseGroup(index: number) {
    this.exerciseGroups.splice(index, 1);
    this.isModuleGroupEditingEnabled = false;
    this.isGroupCreationInProgress = false;
    this.getExerciseList();
  }

  removeExerciseFromGroup(exerciseId: string) {
    const group = this.exerciseGroups[this.selectedGroupIndex];

    if (group.exercises.length === 1) {
      const compatibleExercises = this.ungroupedExercises;
      this.getExerciseList(true, compatibleExercises);
    } else {
      const exerciseIndex = group.exercises.findIndex((exercise) => exercise.id === exerciseId);
      const exercise = group.exercises[exerciseIndex];
      group.exercises.splice(exerciseIndex, 1);
      this.ungroupedExercises.push(exercise);
    }
  }

  saveExerciseGroup(index: number) {
    this.exerciseGroupService
      .createGroup(this.exerciseGroupToSaveData(this.exerciseGroups[index]))
      .pipe(untilDestroyed(this))
      .subscribe(
        (group) => {
          this.exerciseGroups[index] = group;
          this.isGroupCreationInProgress = false;
          this.notificationsService.success('ui.exercise.groupCreatedSuccessfully');
        },
        () => {
          this.notificationsService.error('ui.exercise.groupCreationError');
        }
      );
  }

  addExerciseToGroup(index: number) {
    const groupExercises = this.exerciseGroups[this.selectedGroupIndex].exercises;
    const exercise = this.ungroupedExercises[index];
    const selectedExerciseId = this.ungroupedExercises[index].id;
    this.ungroupedExercises.splice(index, 1);

    if (groupExercises.length === 0) {
      this.getCompatibleExercises(selectedExerciseId);
    }

    this.exerciseGroups[this.selectedGroupIndex].exercises.push(exercise);
  }

  toggleModuleGroupEditing(i: number, cancelled = false) {
    this.selectedGroupIndex = i;
    this.isModuleGroupEditingEnabled = !this.isModuleGroupEditingEnabled;
    if (cancelled) {
      this.getExerciseList();
      return;
    }
    if (!this.isModuleGroupEditingEnabled) {
      const updatedGroup = this.exerciseGroupToSaveData(this.exerciseGroups[i]);
      this.exerciseGroupService
        .updateGroup(updatedGroup)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (group) => {
            this.ungroupedExercises = group.exercises;
            this.notificationsService.success('ui.exercise.groupUpdated');
          },
          error: () => {
            this.notificationsService.error('ui.exercise.groupUpdateFailed');
          },
        });
      return;
    }
    if (this.exerciseGroups[i].exercises.length > 0) {
      this.getCompatibleExercises(this.exerciseGroups[i].exercises[0].id);
    }
  }

  get filteredGroups(): Observable<ExerciseGroup[]> {
    return this.filter$.pipe<ExerciseGroup[]>(
      map((data) => {
        const searchText = data.exerciseOrGroupName?.trim().toLowerCase();
        const filterByName = searchText && searchText.length > 0;
        const filterByRunning = data.runningExercisesOnly;

        if (!filterByName && !filterByRunning) {
          return this.exerciseGroups;
        }

        return this.exerciseGroups
          ?.map((group) => {
            const newGroup = new ExerciseGroup({
              ...group,
              exercises: group.exercises.filter((exercise) => {
                const matchesSearchText = filterByName
                  ? exercise.name.toLowerCase().includes(searchText)
                  : true;
                const matchesRunning = filterByRunning
                  ? exercise.status === ExerciseStatus.RUNNING
                  : true;
                return matchesSearchText && matchesRunning;
              }),
            });

            if (filterByName && group.name.toLowerCase().includes(searchText)) {
              newGroup.exercises = group.exercises.filter((exercise) => {
                return filterByRunning ? exercise.status === ExerciseStatus.RUNNING : true;
              });
            }

            return newGroup as ExerciseGroup;
          })
          .filter((group) => {
            const matchesGroupName = filterByName
              ? group.name.toLowerCase().includes(searchText)
              : true;
            const runningExercises = filterByRunning
              ? group.exercises.some((exercise) => exercise.status === ExerciseStatus.RUNNING)
              : true;

            return (matchesGroupName && runningExercises) || group.exercises.length > 0;
          }) as ExerciseGroup[];
      })
    );
  }

  get filteredExercises(): Observable<ExerciseOverview[]> {
    return this.filter$.pipe(
      map((data) => {
        if (
          (!data.exerciseOrGroupName || data.exerciseOrGroupName.trim().length === 0) &&
          !data.runningExercisesOnly
        )
          return this.ungroupedExercises;

        return this.ungroupedExercises?.filter((exercise) => {
          let matches = true;
          if (data.exerciseOrGroupName) {
            matches =
              exercise.name.toLowerCase().includes(data.exerciseOrGroupName.toLowerCase()) ||
              (exercise.description != null &&
                exercise.description
                  .toLowerCase()
                  .includes(data.exerciseOrGroupName.toLowerCase()));
          }

          if (data.runningExercisesOnly) {
            matches &&= exercise.status === ExerciseStatus.RUNNING;
          }
          return matches;
        });
      })
    );
  }

  private clearResetData(exerciseId: string) {
    this.exerciseResetSteps.delete(exerciseId);
    this.unsubscribeFromStatusEvents(exerciseId);
  }

  private unsubscribeFromStatusEvents(exerciseId: string) {
    if (this.exerciseResetStatusSubscriptions.has(exerciseId)) {
      this.exerciseResetStatusSubscriptions.get(exerciseId).unsubscribe();
      this.exerciseResetStatusSubscriptions.delete(exerciseId);
    }
  }

  private exerciseGroupToSaveData(group: ExerciseGroup): ExerciseGroupSave {
    return new ExerciseGroupSave({
      id: group.id,
      name: group.name,
      exercises: group.exercises.map((exercise) => exercise.id),
    });
  }

  private getCompatibleExercises(exerciseId: string) {
    this.loading = true;
    this.exerciseService
      .getCompatibleExercises(exerciseId)
      .pipe(untilDestroyed(this))
      .subscribe((exercises) => {
        this.ungroupedExercises = this.ungroupedExercises.filter((exercise) => {
          return exercises.indexOf(exercise.id) !== -1;
        });
        this.loading = false;
      });
  }
}
