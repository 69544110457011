import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExerciseOverview } from '../../../../models';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { AfterActionReportService } from '../../../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, first, map } from 'rxjs/operators';
import { Column, ColumnType, NamedEntity, NotificationsService } from '@cybexer/ngx-commons';
import {
  AfterActionReportModel,
  AIFFileModel,
} from '../../../../models/gamenet/after-action-report.model';
import { TranslatePipe } from '@ngx-translate/core';
import { DatePipe } from '../../../../pipes';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'isa-exercise-aar-dialog',
  templateUrl: './exercise-aar-dialog.component.html',
  styleUrls: ['./exercise-aar-dialog.component.scss'],
})
export class ExerciseAarDialogComponent implements OnInit, OnDestroy {
  private static readonly AAR_POLLING_INTERVAL_MS = 5000;
  private static readonly AAR_TEMPLATE_MIMETYPES = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
  ];

  exercise: ExerciseOverview;
  loading = false;
  file: File;
  fileProgress: number = 0;
  response: any;
  uploadInitiated = false;
  existingTemplates: AIFFileModel[] = [];
  selectedTemplate: AIFFileModel;
  existingAars: AfterActionReportModel[] = [];
  addingTemplate = false;
  renaming: boolean = false;
  aarColumns: Column[] = [];

  form: FormGroup;

  private pollingInterval: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ExerciseAarDialogInput,
    private service: AfterActionReportService,
    private notificationsService: NotificationsService,
    private translate: TranslatePipe,
    private datePipe: DatePipe,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      fileName: ['', Validators.required],
      aarTitle: [''],
      selectedTemplate: [null, Validators.required],
      useAI: [true, Validators.required],
      includeAppendixDump: [true, Validators.required],
      includeAttackObjectiveAnalysis: [true, Validators.required],
      includeBlueteamPerformanceAnalysis: [false, Validators.required],
      includeCTFResults: [true, Validators.required],
      includeParticipantFeedback: [false, Validators.required],
      commentVerbosity: [2, [Validators.required, Validators.min(0), Validators.max(4)]],
    });
  }

  ngOnInit(): void {
    this.exercise = this.data.exercise;
    this.initTable();
    this.reloadExistingTemplates();
    this.reloadExistingAars();
    this.pollingInterval = setInterval(() => {
      if (
        !this.existingAars.length ||
        this.existingAars.some((a) => a.status === 'PENDING' || a.status === 'IN_PROGRESS')
      ) {
        this.reloadExistingAars();
      }
    }, ExerciseAarDialogComponent.AAR_POLLING_INTERVAL_MS);
  }

  ngOnDestroy(): void {
    clearInterval(this.pollingInterval);
  }

  reloadExistingTemplates() {
    this.service
      .getAarTemplates()
      .pipe(first())
      .subscribe((templates) => {
        this.existingTemplates = templates;
        const currentTemplate = this.existingTemplates.find(
          (t) => t.id === this.selectedTemplate?.id
        );
        this.form.patchValue({
          selectedTemplate: currentTemplate || this.existingTemplates[0] || null,
        });
        this.selectTemplate(this.form.value.selectedTemplate);
      });
  }

  reloadExistingAars() {
    this.service
      .getAars(this.exercise.id)
      .pipe(
        catchError(() => {
          return of(null);
        }),
        first()
      )
      .subscribe((templates) => {
        if (templates == null) return;
        this.existingAars = templates;
      });
  }

  selectTemplate(template?: AIFFileModel) {
    this.selectedTemplate = template;
    this.form.patchValue({
      fileName: this.selectedTemplate?.name || '',
    });
    this.renaming = false;
  }

  setFile(file: File): void {
    if (!this.uploadInitiated) {
      if (ExerciseAarDialogComponent.AAR_TEMPLATE_MIMETYPES.includes(file.type)) {
        this.file = file;
        this.form.patchValue({
          fileName: file.name,
        });
      } else {
        console.error('Invalid file type for AAR template: ' + file.type);
        this.notificationsService.error('ui.aar.invalidFileType');
      }
    }
  }

  async uploadFiles() {
    if (this.form.invalid) {
      this.notificationsService.error('ui.form.invalid');
      return;
    }

    this.loading = true;
    this.uploadInitiated = true;
    this.response = undefined;

    this.fileProgress = 0;
    const content = await this.file.arrayBuffer();
    this.service
      .uploadTemplateFile(content, this.file.type, this.form.value.fileName)
      .pipe(
        map((event: HttpEvent<any>) => this.getEventMessage(event)),
        untilDestroyed(this)
      )
      .subscribe((result) => {
        if (result) {
          this.selectedTemplate = { id: result, name: this.form.value.fileName };
          this.form.patchValue({
            selectedTemplate: this.selectedTemplate,
            fileName: this.selectedTemplate.name,
          });
          this.addingTemplate = false;
          this.file = undefined;
          this.loading = false;
          this.uploadInitiated = false;
          this.reloadExistingTemplates();
        }
      });
  }

  private getEventMessage(event: HttpEvent<string>): string | undefined {
    switch (event.type) {
      case HttpEventType.Sent:
      case HttpEventType.ResponseHeader:
        return;

      case HttpEventType.UploadProgress:
        const percentDone = event.total ? Math.round((100 * event.loaded) / event.total) : 0;
        this.fileProgress = percentDone;
        return;

      case HttpEventType.Response:
        return event.body;

      default:
        this.response = event;
        this.uploadInitiated = false;
        this.loading = false;
        return;
    }
  }

  createAar(isTestRun: boolean = false) {
    if (this.form.invalid) {
      this.notificationsService.error('ui.form.invalid');
      return;
    }

    this.loading = true;
    this.service
      .createAar(this.exercise.id, {
        templateFileId: this.form.value.selectedTemplate.id,
        aarTitle: this.form.value.aarTitle?.length ? this.form.value.aarTitle : undefined,
        aarPromptOverrideId: this.data.promptOverride?.id,
        isTestRun,
        useAI: this.form.value.useAI,
        commentVerbosity: this.form.value.commentVerbosity,
        includeAppendixDump: this.form.value.includeAppendixDump,
        includeAttackObjectiveAnalysis: this.form.value.includeAttackObjectiveAnalysis,
        includeBlueteamPerformanceAnalysis: this.form.value.includeBlueteamPerformanceAnalysis,
        includeCTFResults: this.form.value.includeCTFResults,
        includeParticipantFeedback: this.form.value.includeParticipantFeedback,
      })
      .subscribe((id) => {
        this.reloadExistingAars();
        this.loading = false;
      });
  }

  deleteTemplate() {
    if (!this.selectedTemplate) return;

    this.service
      .deleteAarTemplate(this.selectedTemplate.id)
      .pipe(first())
      .subscribe(() => {
        this.reloadExistingTemplates();
      });
  }

  downloadTemplate() {
    if (!this.selectedTemplate) return;

    const baseFileName = this.selectedTemplate.name;
    this.service.downloadAarTemplate(
      this.selectedTemplate.id,
      baseFileName +
        (['.doc', '.dotx', '.docx'].some((it) => this.selectedTemplate.name.endsWith(it))
          ? ''
          : '.docx')
    );
  }

  renameTemplate() {
    if (!this.selectedTemplate) return;

    this.service
      .renameAarTemplate(this.selectedTemplate.id, this.form.value.fileName)
      .pipe(first())
      .subscribe(() => {
        this.renaming = false;
        this.reloadExistingTemplates();
      });
  }

  private initTable(): void {
    const aarTemplateMaxLength = 16;
    const getAarTemplateName = (data: string) => {
      return this.existingTemplates.find((t) => t.id === data)?.name || data || '';
    };
    this.aarColumns = [
      new Column({
        name: 'Title',
        translateKey: 'ui.title',
        dataKey: 'aarTitle',
      }),
      new Column({
        name: 'Template',
        translateKey: 'ui.aar.template',
        dataKey: 'aarTemplateDocFileId',
        pipeFn: (data) => {
          const templateName = getAarTemplateName(data);
          return templateName.length > aarTemplateMaxLength
            ? templateName.substring(0, aarTemplateMaxLength) + '…'
            : templateName;
        },
        tooltipFn: (data) => {
          const templateName = getAarTemplateName(data.aarTemplateDocFileId);
          return templateName.length > aarTemplateMaxLength ? templateName : undefined;
        },
      }),
      new Column({
        name: 'Status',
        translateKey: 'ui.status',
        pipeFn: (data: AfterActionReportModel) => {
          if (!data?.status) return undefined;
          if (data.status == 'IN_PROGRESS' && data.progress && data.totalProgress) {
            return this.translate.transform('ui.aar.statusProgress', {
              progressPercent: Math.round((100 * data.progress) / data.totalProgress),
            });
          }
          return this.translate.transform('ui.enums.' + data.status);
        },
        tooltipFn: (data: AfterActionReportModel) => (data.error ? data.error : undefined),
      }),
      new Column({
        name: 'Time',
        translateKey: 'ui.time',
        dataKey: 'updatedAt',
        pipeFn: (data) => this.datePipe.transform(parseInt(data), 'medium'),
      }),
      new Column({
        name: 'Download',
        type: ColumnType.ACTION,
        isNameHidden: true,
        narrow: true,
        icon: 'fas fa-download',
        tooltipFn: () => this.translate.transform('ui.download'),
        isVisibleFn: (data: AfterActionReportModel) => !!data.composedAarDocFileId,
        actionFn: (data: AfterActionReportModel) =>
          this.service.downloadAar(
            data.exerciseId,
            data.composedAarDocFileId,
            `${
              (`${data.aarTitle} ` || 'aar-') +
              this.datePipe.transform(parseInt(data.updatedAt), 'medium')
            }.docx`
          ),
      }),
    ];
  }
}

export type ExerciseAarDialogInput = {
  exercise: ExerciseOverview;
  promptOverride: NamedEntity;
};
