import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxCommonsModule } from '@cybexer/ngx-commons';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'isa-exercise-status-circle',
  standalone: true,
  imports: [TranslateModule, MatTooltipModule, NgxCommonsModule, CommonModule],
  templateUrl: './exercise-status-circle.component.html',
  styleUrl: './exercise-status-circle.component.scss',
})
export class ExerciseStatusCircleComponent {
  @Input() status: string;
  @Input() isResetInProgress?: boolean;
}
