import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import { Exercise } from '../../../../models';
import { ExerciseService, PreferenceService } from '../../../../services';

@Component({
  selector: 'isa-exercise-delete-dialog',
  templateUrl: './exercise-delete-dialog.component.html',
  styleUrls: ['./exercise-delete-dialog.component.scss'],
})
export class ExerciseDeleteDialogComponent implements OnInit {
  exercise: Exercise;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private exerciseService: ExerciseService,
    private notificationsService: NotificationsService,
    private preferenceService: PreferenceService,
    private dialogRef: MatDialogRef<ExerciseDeleteDialogComponent, boolean>
  ) {}

  ngOnInit() {
    this.exercise = this.data['exercise'];
  }

  deleteExercise(exerciseId: string): void {
    this.exerciseService.deleteExercise(exerciseId).subscribe(() => {
      this.exerciseService.exerciseListChanged.emit({
        exerciseId: exerciseId,
        event: 'DELETE',
      });
      const preferences = this.preferenceService.currentPreferences;
      if (preferences.defaultExerciseId === this.exercise.id) {
        this.preferenceService.updatePreferences({ defaultExerciseId: null }).subscribe();
      }
      this.notificationsService.success('ui.exercise.exerciseDeleted');
      this.dialogRef.close(true);
    });
  }
}
